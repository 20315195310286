import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";

import "./app.scss";
import "react-toastify/dist/ReactToastify.css";

import mainFunction from "../utils/extend";

import Pages from './pages';
import Header from "./components/Header";

const App = () => {
    useEffect(()=> {
        mainFunction();
    }, []);

    return (
        <div id="app" className={classNames("app-container")}>
            <ToastContainer
                hideProgressBar={true}
                toastClassName="toast"
                autoClose={3000}
            />
            <Header />
            <div id="content" className={classNames("app-content")}>
                <Pages />
            </div>                
        </div>        
    );
}

export default App;
