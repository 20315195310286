import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";

import WaiterButton from "../WaiterButton";

const ConfirmDialog = ({ t, onCancel, onConfirm }) => {
    return (
        <div className={classNames("confirmDialog")}>
            <WaiterButton type={WAITER_TYPES.button} 
                typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                title={t("basic.SURE_BUTTON")} 
                onClick={onConfirm} 
            />
            <WaiterButton type={WAITER_TYPES.button} title={t("basic.CANCEL_BUTTON")} 
                onClick={onCancel} 
            />
        </div>
    );
}

export default withTranslation()(ConfirmDialog);