import i18n from "../i18n";

import MenuModel from "./menuModel";

export function createHeaderMenu() {
  return [
    new MenuModel({
      pathname: "/depotIntegration",
      title: i18n.t("header.DEPOT_INTEGRATION_LABEL"),
      active: location => location.pathname === "/depotIntegration"
    }),
    new MenuModel({
      pathname: "/geoLocation",
      title: i18n.t("header.GEO_LOCATION_LABEL"),
      active: location => location.pathname === "/geoLocation"
    }),
    new MenuModel({
      pathname: "/billing",
      title: i18n.t("header.BILLING_LABEL"),
      active: location => location.pathname === "/billing"
    })
  ];
}
