import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import "./index.scss";

import MenuService from "../../../services/menu";
import { TYPE_MENU } from "../../../utils/enums";

const HeaderMenu = ({ history, location })=> {
    const [menuItems, setMenuItems] = useState([]);

    useEffect(()=> {
        setMenuItems(MenuService.instance.getMenu(TYPE_MENU.header));
    }, []);

    return (
        <menu className={classNames("headerMenu")}>
            {menuItems.map(headerMenu => (
                <li key={`headerMenu-${headerMenu.title}`}
                    className={classNames("menuItem", { active: headerMenu.active(location) })}
                    onClick={() => history.push(headerMenu.pathname)}
                >
                    <div className={classNames("menuItemTitle")}>{headerMenu.title}</div>
                </li>
            ))}
        </menu>
    );
}

export default withRouter(HeaderMenu);