import React from "react";
import classNames from "classnames";
import "./index.scss";

const MapMarker = ({ id, icon, type, onClick }) => {
    return (
        <div className={classNames("mapMarker", type)} onClick={onClick}>
            {icon && (<img alt={id} src={icon} />)}
        </div>
    );
}

export default MapMarker;