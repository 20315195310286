import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./index.scss";

import { isAuthenticated, logout } from "../../../utils/auth-util";

import Login from "../../components/Login";

const LoginPage = ({
  history
}) => {
  useEffect(()=> {
      if(isAuthenticated()) {
        logout();
        history.push("/login");
      };
  }, [history]);

  return (
    <section id="login">
      <section id="login-header" />
      <section id="login-content">
        <Login />
      </section>
      <section className="login-footer">
        {`©${new Date().getFullYear()} GeoRest, Ltd. All rights reserved.`}
      </section>
    </section>
  );
};

export default withRouter(LoginPage);