import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { getAddressSuggestions, getAddressByPlaceId } from "../../../services/locations";
import { isSameObjects } from "../../../utils/objects-util";

import InputControl from "../InputControl";


const MapSearchControl = ({ t, map, onSelectSuggestedAddress }) => {
    const [searchString, setSearchString] = useState("");
    const [suggestedAddresses, setSuggestedAddresses] = useState([]);
    const [selectedSuggestedAddress, setSelectedSuggestedAddress] = useState([]);

    const handleOnSearch = val => {
        setSearchString(val);

        getAddressSuggestions(val).then(data=> {
            setSuggestedAddresses(data);
        })
    }

    const handleOnSelect = val => {
        getAddressByPlaceId(map, val.place_id).then(location=> {
            if(location) {
                setSuggestedAddresses(suggestedAddresses.map(suggestedAddress=> suggestedAddress.place_id === val.place_id
                    ? { ...suggestedAddress, location }
                    : suggestedAddress
                ));
                setSelectedSuggestedAddress({ ...val, location });
                onSelectSuggestedAddress && onSelectSuggestedAddress({ ...val, location });
            } else {
                setSelectedSuggestedAddress(val);
            }            
        }, ()=> {
            setSelectedSuggestedAddress(val);
        })
    }

    return (
        <div className={classNames("mapSearchControl")}>
            <div className={classNames("search")}>
                <InputControl isDebounce={true}
                    debounce={1000}
                    title={t("map.SEARCH_LABEL")}
                    value={searchString} 
                    onChange={handleOnSearch}
                />
            </div>
            {suggestedAddresses && suggestedAddresses.length > 0 && (
                <div className={classNames("suggestedAddresses")}>
                    <div className={classNames("title")}>{t("map.SUGGESTED_ADDRESSES_LABEL")}</div>
                    <ul>
                        {suggestedAddresses.map((suggestedAddress, index)=> (
                            <li key={`suggestedAddress-${index}`} 
                                className={classNames({ active: isSameObjects(suggestedAddress, selectedSuggestedAddress) })}
                                onClick={()=> handleOnSelect(suggestedAddress)}
                            >
                                {suggestedAddress.address}
                            </li>
                        ))}
                    </ul>
                </div>
            )}            
        </div>
    );
}

export default withTranslation()(MapSearchControl);