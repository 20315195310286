import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";
import { generateUniqueString } from "../../../utils/objects-util";

import InputControl from "../InputControl";
import WaiterButton from "../WaiterButton";

const DepotPereferialUser = ({ t, onClick }) => {
    const [data, setData] = useState({ userName: "", password: "" });

    const handleOnGenerate = () => {
        return new Promise(resolve=> {
            resolve();
            setData({
                userName: generateUniqueString(6),
                password: generateUniqueString(6)
            })
        });
    }

    const handleOnUpdate = () => {
        return new Promise((resolve, reject)=> {
            onClick(data).then(()=> {
                resolve();
                setData({ userName: "", password: "" });
            }, error=> {
                reject(error);
            });            
        });
    }

    return (
        <div className={classNames("depotPereferialUser")}>
            <div className={classNames("depotPereferialUserItems")}>
                <div className={classNames("depotPereferialUserItem")}>
                    <InputControl title={t("depot-integration.PEREFERIAL_USER_LABEL")} 
                        value={data.userName}
                        onChange={val=> setData({ ...data, userName: val })}
                    />
                </div>
                <div className={classNames("depotPereferialUserItem")}>
                    <InputControl title={t("depot-integration.PASSWORD_USER_LABEL")} 
                        value={data.password}
                        onChange={val=> setData({ ...data, password: val })}
                    />
                </div>
            </div>
            <div className={classNames("depotPereferialUserControls")}>
                <WaiterButton type={WAITER_TYPES.button} 
                    typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                    title={t("basic.UPDATE_BUTTON")} 
                    disabled={!data.userName && !data.password}
                    onClick={handleOnUpdate} 
                />
                <WaiterButton type={WAITER_TYPES.button} title={t("basic.GENERATE_BUTTON")} 
                    onClick={handleOnGenerate} 
                />
            </div>
        </div>
    );
}

export default withTranslation()(DepotPereferialUser);