import React, { useState } from "react";
import classNames from "classnames";
import "./index.scss";

import { WAITER_TYPES } from "../../../utils/enums";

import DropdownInput from "../DropdownInput";
import InputControl from "../InputControl";
import WaiterButton from "../WaiterButton";

const GeoLocationInfoDataItemCombo = ({ 
    title, 
    comboValues,
    listValues, 
    onChangeItemCombo, onChangeItemList, onSelectCombo
}) => {
    const [selectedListIndex, setSelectedListIndex] = useState(null);
    const [newListItem, setNewListItem] = useState("");

    const handleOnRemoveClick = () => {
        return new Promise((resolve=> {
            resolve();
            onChangeItemList(listValues[selectedListIndex], true);
            setSelectedListIndex(null);
        }));
    }

    const handleOnItemSelect = index => {
        setSelectedListIndex(selectedListIndex === index ? null : index);
    }

    const handleOnAddAliasClick = () => {
        return new Promise((resolve, reject)=> {
            resolve();

            if(newListItem && !listValues.find(f=> f.value === newListItem)) {
                onChangeItemList(newListItem);
                setNewListItem("");
            }            
        });
    }

    const handleOnComboValuesAdd = val => {
        if(!comboValues.find(f=> f.value === val)) {
            onChangeItemCombo(val);
        }
    }

    return (
        <div className={classNames("geoLocationInfoDataItemCombo")}>
            <div className={classNames("geoLocationInfoDataItemHeader")}>
                <DropdownInput title={title} 
                    value={comboValues} 
                    onSelect={onSelectCombo}
                    onChange={handleOnComboValuesAdd} 
                />
            </div>
            <div className={classNames("geoLocationInfoDataItemData")}>
                <ul>
                    {listValues.map((item, index)=> (
                        <li key={`geoLocationInfoDataItemListItem-${index}`} 
                            className={classNames({ selected: index === selectedListIndex })} 
                            onClick={()=> handleOnItemSelect(index)}
                        >
                            {item.value}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={classNames("geoLocationInfoDataItemFooter")}>
                {(selectedListIndex || selectedListIndex === 0) && (<WaiterButton type={WAITER_TYPES.icon} icon="delete" onClick={handleOnRemoveClick} />)}
                <div className={classNames("inputAddContainer")}>
                    <InputControl value={newListItem} onChange={val=> setNewListItem(val)} />
                    <WaiterButton type={WAITER_TYPES.icon} icon="add" onClick={handleOnAddAliasClick} />
                </div>
            </div>
        </div>
    );
}

export default GeoLocationInfoDataItemCombo;