import React, {useEffect, useState} from 'react';
import './style.scss';
import {ProductPreview} from "delivapp-ordering";
import SearchBox from "../SearchBox";
import ProductConstructorModal from "../ProductConstructorModal";
import WaiterButton from "../WaiterButton";
import {WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES} from "../../../utils/enums";

const MenuControl = ({entityId, menu, products, onDelete, onAdd, onEdit, onlyMenu, onCloseEdit, editIndex}) => {
    const [searchString, setSearchString] = useState("");
    const [showMenu, setShowMenu] = useState( onlyMenu ?? false);
    const [selectProduct, setSelectProduct] = useState(null);
    const [productToEdit, setProductToEdit] = useState(null);
    const [indexEdit, setIndexEit] = useState(null);

    useEffect(() => {
        if(editIndex || editIndex === 0) {
            setIndexEit(editIndex);
            setProductToEdit(products[editIndex]);
        }
        // eslint-disable-next-line
    }, [editIndex]);

    const  getProduct = prod => {
        if(!menu) {
            return null
        }
        const allProducts = menu.categories
            .flatMap(allCategory => allCategory)
            .flatMap(cat => cat.products);
        const found = allProducts.find(el => el.id === prod.id);
        return {
            ...found,
            options: prod.options,
            quantity: prod.quantity,
            comment: prod.comment,
            name_on_box: prod.name_on_box,
            full_price: prod.full_price
        };
    }

    const getProductItem = (el, index) => {
        const product = getProduct(el);
        if(!product) {
            return null;
        }
        const onClick = () => {
            setProductToEdit(el);
            setIndexEit(index);
        }
        const description = product.options.map((option, i) =>
            `${option.name}:&nbsp;${(option.suboptions || []).filter(s => s.quantity).map(s => s.name + (s.quantity > 1 ? (" x" + s.quantity) : ""))}`);
        return <ProductPreview
            key={el.id}
            name={product.name}
            id={product.id}
            description={description.join('; ')}
            oldPrice={el.quantity + "x " + product.price}
            price={el.full_price}
            onClick={onClick}
            onClickTrash={() => handleOnDelete(index)}
            trashIcon={true}
            glutenFree={product.gluten_free}
            vegan={product.vegan}
            vegetarian={product.vegetarian}
            spicy={product.spicy}
            image={{
                src: product.images || '',
                alt: product.name,
                settings: product.image_settings
            }}
            currency={menu.currency}
        />
    }

    const handleOnDelete = (index) => {
        onDelete(index);
    }

    const handleOnAddToCart = (prod) => {
        if(indexEdit !== null) {
            onEdit(prod.product, indexEdit);
        } else {
            onAdd(prod.product);
        }
    }

    const handleOnCloseProduct = () => {
        setSelectProduct(null);
        setProductToEdit(null);
        setIndexEit(null);
        onCloseEdit && onCloseEdit();
    }

    const filterBySearchString = (product) => {
        return product.name?.toLowerCase().trim().includes(searchString?.toLowerCase().trim())
            || product.description?.toLowerCase().trim().includes(searchString?.toLowerCase().trim());
    }

    const goToCategory = category => {
        const node = document.querySelector('#CAT_ID_' + category);
        node.scrollIntoView({ behavior: 'smooth' });
    }

    const handleOnOpen = () => {
        return new Promise((resolve, reject) => {
            setShowMenu(!showMenu)
            resolve();
        });
    }

    return (
        <div className={'customerServiceProductControl'}>
            {!onlyMenu && (
                <>
                    {products && !!products.length && (<div className={'cart'}>
                        {products.map((product, index) => getProductItem(product, index))}
                    </div>)}
                    <WaiterButton
                        type={WAITER_TYPES.button}
                        typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                        title={"Show menu"}
                        onClick={handleOnOpen}
                        className={"showMenu"}
                    />
                </>
            )}

            {showMenu && (
                <div className={'headerSection'}>
                    <SearchBox onChange={setSearchString} useValue={searchString}/>
                    <div className={'categories'}>
                        {menu && menu.categories.filter(c => c.enabled && c.products.length).map(c => (
                            <div className={'category'} onClick={() => goToCategory(c.id)} >{c.name}</div>
                        ))}
                    </div>
                </div>
            )}

            {showMenu && menu && (
                <div className={'productsContainer'}>
                    {menu.categories.filter(c => c.enabled && c.products.length).map(cat => (
                        <div className={'categoriesSection'} key={cat.id}>
                            <div className={'categoriesSectionName'} id={`CAT_ID_${cat.id}`}>{cat.name}</div>
                            <div className={'productsList'}>
                                {cat.products && cat.products.filter(p => p.enabled).filter(filterBySearchString).map(product => (
                                    <ProductPreview
                                        key={product.id}
                                        name={product.name}
                                        id={product.id}
                                        description={product.description}
                                        oldPrice={product.price_before_discount}
                                        price={product.price}
                                        onClick={() => setSelectProduct(product)}
                                        glutenFree={product.gluten_free}
                                        vegan={product.vegan}
                                        vegetarian={product.vegetarian}
                                        spicy={product.spicy}
                                        image={{
                                            src: product.images || '',
                                            alt: product.name,
                                            settings: product.image_settings,
                                            autoPlay: false,
                                            controls: true
                                        }}
                                        currency={menu && menu.currency}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {(selectProduct || productToEdit) && (
                <ProductConstructorModal
                    entityId={entityId}
                    handleOnAddToCart={handleOnAddToCart}
                    business_id={menu.id}
                    product={selectProduct ? selectProduct : getProduct(productToEdit)}
                    productEdit={productToEdit ? {product: productToEdit, full_price: productToEdit.full_price} : null}
                    currency={menu && menu.currency}
                    onClose={handleOnCloseProduct}
                    isOpen={!!selectProduct || !!productToEdit}
                />
            )}
        </div>
    )
}

export default MenuControl;