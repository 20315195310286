import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import "./index.scss";

import { authorize } from "../../../api/auth-requests";
import { saveUserData } from "../../../services/dataStorage";
import { WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";
import { isAuthenticated, logout } from "../../../utils/auth-util";

import WaiterButton from "../WaiterButton";
import LoaderControl from "../LoaderControl";
import { getErrorMessage } from "../../../utils/request-util";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEmailRequired: false,
      isNotValidEmail: false,
      isPasswordRequired: false,
      credentials: {
        username: "",
        password: "",
        rememberMe: false
      },
      inProcess: false
    };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentWillUnmount() {
    this.isUnmount = true;
  }

  componentDidMount() {
    if(this.state.inProcess) {
      this.setState({ inProcess: false });
    }    
  }

  login = () => new Promise((resolve, reject) => (this.loginPromise = { resolve, reject }));

  handleOnSubmit = event => {  
    const { history } = this.props;
    const { username, password, rememberMe } = this.state.credentials;
    
    if(event && event.preventDefault) {
      event.preventDefault();
    }

    if(!username || !password) {
      this.loginPromise.reject();
    } else {
      logout();

      this.setState({ inProcess: true }, ()=> {
        authorize(username, password, rememberMe)
          .then(response=> {
            if(response && response.status === 200 && response.data) {
              saveUserData(response.data, rememberMe, username);
              this.loginPromise && this.loginPromise.resolve();
              this.setState({ inProcess: false }, ()=> history.push("/"));
            } else {              
              const errorString = getErrorMessage(response);
              toast.error(errorString);
              this.loginPromise && this.loginPromise.reject(errorString);
              this.setState({ inProcess: false });
            }            
          }, error=> {
            const errorString = getErrorMessage(error);
            toast.error(errorString);
            this.loginPromise && this.loginPromise.reject(errorString);
            this.setState({ inProcess: false });
          });
      });
    }
  };

  render() {
    const { inProcess, isEmailRequired, isNotValidEmail, isPasswordRequired, credentials } = this.state;
    const { t } = this.props;
    const isAuth = isAuthenticated();

    return (
      <div className={classNames("login-wrapper", { inProcess: inProcess || isAuth })}>
        {!inProcess && !isAuth && (
          <form name="loginForm" className="form-signin" onSubmit={this.handleOnSubmit}>
            <div className="logo-title">
              <label className="form-signin-heading">{t("login.LOGIN_LABEL")}</label>
            </div>
            <input type="text" name="username" placeholder={t("login.LOGIN_LABEL")} value={credentials.username}
              className="form-control"
              onChange={e => this.setState({ credentials: { ...credentials, username: e.currentTarget.value } })}
            />
            {isEmailRequired && (<p className="help-inline">Field email is required.</p>)}
            {isNotValidEmail && (<p className="help-inline">Enter a valid email.</p>)}
            <input type="password" name="password" placeholder="Password" value={credentials.password}
              className="form-control"
              onChange={e => this.setState({ credentials: { ...credentials, password: e.currentTarget.value } })}
            />
            {isPasswordRequired && (<p className="help-block">Field password is required.</p>)}
            <div className="forgot-password">
              <a href="/login">Forgot your password?</a>
            </div>
            <div className="btn-block">
              <div className="remember">
                <label className="checkContainer">
                  <input type="checkbox" value={credentials.rememberMe}
                    onChange={() => this.setState({ credentials: {
                      ...credentials,
                      rememberMe: !credentials.rememberMe
                    }})}
                  />
                  <span className="checkmark" />
                </label>
                <span className="remember-me">{t("login.REMEMBER_ME_LABEL")}</span>
              </div>
              <WaiterButton type={WAITER_TYPES.button} 
                typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                title={t("login.LOGIN_BUTTON_LABEL")}
                handleOnClick={this.login}
              />
            </div>
          </form>
        )}
        {(inProcess || isAuth) && (<LoaderControl />)}
      </div>
    );
  }
}

export default withRouter(withTranslation()(Login));
