import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./index.scss";

import { checkIsAuthenticated } from "../../../utils/auth-util";

import DepotIntegration from "../../components/DepotIntegration";

 const DepotIntegrationPage = ({ history }) => {
    useEffect(()=> {
        checkIsAuthenticated(history);
    }, [history]);

    return (
        <div id="depotIntegrationPage">
            <DepotIntegration />
        </div>
    );
 };

export default withRouter(DepotIntegrationPage);