import { COLOR_CODES, ICONS_MARKER_TYPE, TYPE_MARKER } from "../utils/enums";
import { convertLocationToBaseModel } from "./locations";

let singleton = Symbol();
let singletonEnforcer = Symbol();

let TEMPLATE =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" x="0px" y="0px" width="22.688px" height="31.333px" viewBox="0 0 22.688 31.333" enable-background="new 0 0 22.688 31.333" ><g><g><g opacity="0.26">';
TEMPLATE +=
  '<path d="M11.813,0.958c-5.551,0-10.051,4.501-10.051,10.05c0,1.833,0.493,3.546,1.35,5.025l8.701,15.074l8.701-15.074 c0.857-1.479,1.35-3.192,1.35-5.025C21.864,5.459,17.363,0.958,11.813,0.958z"/></g>';
TEMPLATE +=
  '<path fill="{{ bgcolor }}" d="M11.113,0.258c-5.551,0-10.051,4.501-10.051,10.05c0,1.833,0.493,3.546,1.35,5.025l8.701,15.074 l8.701-15.074c0.857-1.479,1.35-3.192,1.35-5.025C21.164,4.759,16.663,0.258,11.113,0.258z"/></g>';
TEMPLATE +=
  '<g><g opacity="0.53"><circle cx="10.745" cy="10.594" r="6"/></g><circle fill="{{ circleColor }}" cx="11.245" cy="11.094" r="6"/></g>';

let TEMPLATE_EMPTY = "</g></svg>";

export default class MarkerIcons {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use MarkerIcons.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new MarkerIcons(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  getMarker = (type, id, location, color) => {
    if(!location) return null;
    let marker = null;
    const icon = this.getIconMarker(null, color);

    switch (type) {
        case TYPE_MARKER.order:
            marker = { id, icon, location: convertLocationToBaseModel(location), type };
            break;    
        default:
            marker = { id, icon, location: convertLocationToBaseModel(location), type };
            break;
    }

    return marker;
  }

  getIconMarker = (iconType, color) => {
    return this.addItemType(iconType, color);
  }

  addSvgEmpty(tmpTemplate) {
    return {
        url: "data:image/svg+xml;charset=UTF-8;base64," + window.btoa(tmpTemplate + TEMPLATE_EMPTY),
        fillOpacity: 1,
        strokeWeight: 0
    };
  }

  addItemType = (iconType = ICONS_MARKER_TYPE.empty, colorCode) => {
    const codes = COLOR_CODES[colorCode || "default"];
    const tmpTemplate = TEMPLATE
        .replace("{{ bgcolor }}", codes[0])
        .replace("{{ circleColor }}", codes[1]);

    return {
        ...this.addSvgEmpty(tmpTemplate),
        markerType: iconType
    };
  }
}
