import React, {useState} from 'react';
import './style.scss';
import {IconCart, IconEditPen, IconPlay, IconTrash} from "delivapp-ordering";
import {BILLING_STATEMENT_STATUS, BILLING_STATEMENT_STATUS_VALUES} from "../../../utils/enums";
import StatementPaymentsModal from "../StatementPaymentsModal";

export const BillingStatementsList = ({ statements, onDelete, onEdit, onApprove, onManual }) => {
    const [statementPayments, setStatementsPayments] = useState(null);

    const handleOnManual = async (payments) => {
        return new Promise(async (res, rej) => {
            setStatementsPayments(null);
            await onManual(statementPayments, payments);
        });
    }

    return (
      <div className={'statementList'}>
        <h2>Statements</h2>
        {!!statements.length && (
          statements.map((statement) => (
              <div className={'statementCard'} key={statement.Id}>
                  <div className={'statementItem'}>
                      <span className={'statementItemHeader'}>Date</span>
                      <span className={'statementItemContent'}>{new Date(statement.StatementDate).toLocaleDateString()}</span>
                  </div>
                  <div className={'statementItem'}>
                      <span className={'statementItemHeader'}>Due Date</span>
                      <span className={'statementItemContent'}>{new Date(statement.DueDate).toLocaleDateString()}</span>
                  </div>
                  <div className={'statementItem'}>
                      <span className={'statementItemHeader'}>Creation Date</span>
                      <span className={'statementItemContent'}>{new Date(statement.CreationDate).toLocaleDateString()}</span>
                  </div>
                  <div className={'statementItem'}>
                      <span className={'statementItemHeader'}>Status</span>
                      <span className={'statementItemContent'}>{BILLING_STATEMENT_STATUS_VALUES[statement.Status]}</span>
                  </div>
                  <div className={'statementItem'}>
                      <span className={'statementItemHeader'}>Price</span>
                      <span className={'statementItemContent'}>{statement.Price} {statement.Currency}</span>
                  </div>
                  <div className={'statementItem'}>
                      <span className={'statementItemHeader'}>Payments</span>
                      <span className={'statementItemContent'}>{statement.Payments.map(p => p.price).join(', ') || '-'}</span>
                  </div>
                  <div className={'statementItemControls'}>
                      {![BILLING_STATEMENT_STATUS.Rejected, BILLING_STATEMENT_STATUS.Approved].includes(statement.Status) && (
                          <IconCart size={20} onClick={() => setStatementsPayments(statement)}/>
                      )}
                      {statement.Status === BILLING_STATEMENT_STATUS.Draft && (
                          <IconPlay size={25} onClick={() => onApprove(statement)}/>
                      )}
                      {statement.Status === BILLING_STATEMENT_STATUS.Draft && (
                          <IconEditPen size={"small"} onClick={() => onEdit(statement)}/>
                      )}
                      {![BILLING_STATEMENT_STATUS.Rejected, BILLING_STATEMENT_STATUS.Approved].includes(statement.Status) && (
                          <IconTrash size={"small"} onClick={() => onDelete(statement)}/>
                      )}
                  </div>
              </div>
          ))
        )}
        {!statements.length && (
            <p>No statements</p>
        )}
        <StatementPaymentsModal
          onSave={handleOnManual}
          payments={statementPayments?.Payments}
          onClose={() => setStatementsPayments(null)}
          isOpen={statementPayments}
        />
      </div>
    );
  };

  export default BillingStatementsList;

  