import React, { useState } from "react";
import classNames from "classnames";
import "./index.scss";

import { WAITER_TYPES } from "../../../utils/enums";

import InputControl from "../InputControl";
import WaiterButton from "../WaiterButton";

const GeoLocationInfoDataItemInput = ({ value, onChange }) => {
    const [inputValue, setInputValue] = useState("");
    const [selected, setSelected] = useState(null);

    const handleOnAddClick = () => {
        return new Promise((resolve, reject)=> {
            if(!value.find(f=> f.value === inputValue)) {
                resolve();
                const newVal = { key: inputValue, value: inputValue };
                onChange(value.concat([newVal]));
                setSelected(newVal);
            } else {
                reject();
            }
        });
    }

    const handleOnRemoveClick = () => {
        return new Promise((resolve, reject)=> {
            if(selected) {
                resolve();
                onChange(value.filter(f=> f.key !== selected.key));
                setSelected(null);
                setInputValue("");
            } else {
                reject();
            }            
        });
    }

    const handleOnInputChange = val => {
        if(selected) {
            setSelected(null);
        }        
        setInputValue(val);
    }

    const handleOnItemSelect = index => {
        if(selected) {
            if(selected !== value[index]) {
                setSelected(value[index]);
                setInputValue(value[index].value);
            }  else {
                setSelected(null);
                setInputValue("");
            }
        } else {
            setSelected(value[index]);
            setInputValue(value[index].value);
        }
    }

    return (
        <div className={classNames("geoLocationInfoDataItemInput")}>
            <div className={classNames("geoLocationInfoDataItemHeader")}>
                <InputControl value={inputValue} onChange={val=> handleOnInputChange(val)} />
                <WaiterButton type={WAITER_TYPES.icon} icon="add" onClick={handleOnAddClick} />
            </div>
            <div className={classNames("geoLocationInfoDataItemData")}>
                <ul>
                    {(value || []).map((item, index)=> (
                        <li key={`geoLocationInfoDataItemListItem-${index}`} 
                            className={classNames({ selected: selected && item.value === selected.value })} 
                            onClick={()=> handleOnItemSelect(index)}
                        >
                            {item.value}
                        </li>
                    ))}
                </ul>
            </div>            
            <div className={classNames("geoLocationInfoDataItemFooter")}>
                {selected && (<WaiterButton type={WAITER_TYPES.icon} icon="delete" onClick={handleOnRemoveClick} />)}
            </div>
        </div>
    );
}

export default GeoLocationInfoDataItemInput;