import React, { useEffect, useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { fetchRegionData, updateRegionData, fetchRegionCities } from "../../../api/restaurant-requests";
import { checkResponseIsSuccessfull, getErrorMessage } from "../../../utils/request-util";
import { isSameObjects, objectKeysToUpperLowerCase } from "../../../utils/objects-util";
import { TYPE_MARKER, WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";
import MarkerIcons from "../../../services/markericons";

import GeoLocationInfoDataItemInput from "../GeoLocationInfoDataItemInput";
import GeoLocationInfoDataCity from "../GeoLocationInfoDataCity";
import GeoLocationInfoDataStreet from "../GeoLocationInfoDataStreet";
import MapControl from "../MapControl";
import InputControl from "../InputControl";
import WaiterButton from "../WaiterButton";

const GeoLocationInfoData = ({ t, value }) => {
    const [region, setRegion] = useState(null);
    const [editRegion, setEditRegion] = useState(null);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [streetLocation, setStreetLocation] = useState(null);
    const [streetSelected, setStreetSelected] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);

    const markers = [MarkerIcons.instance.getMarker(TYPE_MARKER.order, null, currentLocation)]
        .concat([MarkerIcons.instance.getMarker(TYPE_MARKER.order, null, streetLocation, "green")])
        .filter(marker=> marker);

    const handleOnChange = propValue => {
        return new Promise(resolve=> {
            resolve();
            setEditRegion({ ...editRegion, ...propValue});
        });
    }

    const handleOnSave = () => {
        return new Promise((resolve, reject)=> {
            updateRegionData(value, objectKeysToUpperLowerCase(editRegion, true)).then(response=> {
                if(checkResponseIsSuccessfull(response, false)) {
                    resolve();
                    setRegion(editRegion);
                } else {
                    reject();
                }
            }, error=> reject(getErrorMessage(error)));
        });
    }

    const handleOnStreetLocationChange = () => {
        return new Promise(resolve=> {
            resolve();
            setStreetLocation(currentLocation);
        });
    }

    const getRegionData = useCallback(() => {
        if(value) {
            fetchRegionData(value).then(response=> {
                if(checkResponseIsSuccessfull(response)) {
                    const data = objectKeysToUpperLowerCase(response.data);
                    setRegion(data);
                    setEditRegion(data);
                }
            });
        } else {
            setRegion(null);
            setEditRegion(null);
        }        
    }, [value]);

    const getCities = useCallback(() => {
        if(value) {
            fetchRegionCities(value).then(response=> {
                if(checkResponseIsSuccessfull(response)) {
                    setCities(objectKeysToUpperLowerCase(response.data));
                }
            });
        } else {
            setCities([]);
        }        
    }, [value]);

    useEffect(()=> {
        getRegionData();
        getCities();
    }, [value, getRegionData, getCities]);

    if(!value) {
        return (<div className={classNames("geoLocationInfoData")}></div>);
    }

    return (
        <div className={classNames("geoLocationInfoData")}>
            <div className={classNames("geoLocationInfoDataContainer")}>
                {editRegion && (
                    <div className={classNames("geoLocationInfoDataItems")}>
                        <div className={classNames("geoLocationInfoDataItem")}>
                            <div className={classNames("title")}>{t("geo-location.INVALID_CHARS_LABEL")}</div>
                            <div className={classNames("geoLocationInfoDataItemContainer")}>
                                <GeoLocationInfoDataItemInput value={editRegion.invalidChars.map(m=> ({ key: m, value: m }))} 
                                    onChange={val=> handleOnChange({ invalidChars: val.map(m=> m.value) })} 
                                />
                            </div>                        
                        </div>
                        <div className={classNames("geoLocationInfoDataItem")}>
                            <div className={classNames("title")}>{t("geo-location.FILTER_LABEL")}</div>
                            <div className={classNames("geoLocationInfoDataItemContainer")}>
                                <GeoLocationInfoDataItemInput value={editRegion.filter.map(m=> ({ key: m, value: m }))} 
                                    onChange={val=> handleOnChange({ filter: val.map(m=> m.value) })} 
                                />
                            </div>                        
                        </div>
                        <div className={classNames("geoLocationInfoDataItem")}>
                            <div className={classNames("title")}>{t("geo-location.SECOND_CHANCE_FILTER_LABEL")}</div>
                            <div className={classNames("geoLocationInfoDataItemContainer")}>
                                <GeoLocationInfoDataItemInput value={editRegion.secondChanceFilter.map(m=> ({ key: m, value: m }))} 
                                    onChange={val=> handleOnChange({ secondChanceFilter: val.map(m=> m.value) })} 
                                />
                            </div>                        
                        </div>                    
                    </div>
                )}
                <div className={classNames("geoLocationInfoDataControls")}>
                    <WaiterButton type={WAITER_TYPES.button} 
                        typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                        title={t("basic.SAVE_BUTTON")} 
                        disabled={!region || isSameObjects(region, editRegion)}
                        onClick={handleOnSave} 
                    />
                </div>
            </div>
            <div className={classNames("geoLocationInfoDataContainer")}>
                <div className={classNames("geoLocationInfoDataItems", "address")}>
                    <div className={classNames("geoLocationInfoDataItem")}>
                        <div className={classNames("title")}>{t("geo-location.CITY_LABEL")}</div>
                        <div className={classNames("geoLocationInfoDataItemContainer")}>
                            <GeoLocationInfoDataCity                        
                                value={city && city.key} 
                                items={cities} 
                                region={value} 
                                onCitySelect={val=> setCity(val)}
                                onChangeCityValues={newCity=> setCities(cities.concat([newCity]))}
                            />
                        </div>                        
                    </div>
                    <div className={classNames("geoLocationInfoDataItem")}>
                        <div className={classNames("title")}>{t("geo-location.STREET_LABEL")}</div>
                        <div className={classNames("geoLocationInfoDataItemContainer")}>
                            <GeoLocationInfoDataStreet 
                                city={city} 
                                location={streetLocation} 
                                onStreetLocation={location=> setStreetLocation(location)} 
                                onStreetSelect={val=> setStreetSelected(val)}
                            />
                        </div>                        
                    </div>
                </div>
            </div> 
            {streetSelected && (
                <div className={classNames("geoLocationInfoDataContainer", "street")}>
                    <MapControl 
                        markers={markers}
                        onClick={location=> setCurrentLocation(location)}
                        onMarkerClick={location=> setStreetLocation(location)}
                    />
                </div> 
            )}            
            {streetSelected && (
                <div className={classNames("geoLocationInfoDataContainer", "street")}>
                    <InputControl
                        title={t("geo-location.STREET_LOCATION_LATITUDE_LABEL")}
                        value={currentLocation && currentLocation.latitude} 
                        onChange={latitude=> setStreetLocation({ ...currentLocation, latitude })}
                    />
                    <InputControl
                        title={t("geo-location.STREET_LOCATION_LONGITUDE_LABEL")}
                        value={currentLocation && currentLocation.longitude} 
                        onChange={longitude=> setStreetLocation({ ...currentLocation, longitude })} 
                    />
                    <div className={classNames("geoLocationInfoDataControls")}>
                        <WaiterButton type={WAITER_TYPES.button} 
                            typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                            title={t("basic.SAVE_BUTTON")} 
                            disabled={!currentLocation || isSameObjects(currentLocation, streetLocation)}
                            onClick={handleOnStreetLocationChange} 
                        />
                    </div>
                </div>
            )}                                            
        </div>
    );
}

export default withTranslation()(GeoLocationInfoData);