export const PUBLIC_DATA_KEYS = {
    accessToken: "accessToken",
    refreshToken: "refreshToken",
    tokenType: "tokenType",
    rememberMe: "rememberMe",
    currentUserName: "currentUserName"
};

export const TYPE_STORAGE = {
    local: "local",
    session: "session",
    db: "db"
};

export const WAITER_TYPES = {
    button: "button",
    icon: "icon"
};

export const TYPE_MENU = {
    header: "header"
}

export const WAITER_BUTTON_TYPE_STYLE = {
    default: "default",
    confirm: "confirm"
}

export const DIALOG_ACTION_TYPE = {
    delete: "delete",
    save: "save",
    update: "update"
}

export const CONTROL_TYPE = {
    combobox: "combobox",
    input: "input"
}

export const TYPE_MARKER = {
    order: "order"
}

export const ICONS_MARKER_TYPE = {
    empty: 1,
    point: 2,
    checked: 3,
    canseled: 4
}

export const COLOR_CODES = {
    default: ["#fff", "#d8d8d8"],
    green: ["#4caf50", "#357a38"]
};

export const BILLING_STATEMENT_STATUS = {
    Draft: 0,
    Approved: 1,
    Payed: 2,
    Rejected: 3,
}

export const BILLING_STATEMENT_STATUS_VALUES = {
    [BILLING_STATEMENT_STATUS.Draft]: "Draft",
    [BILLING_STATEMENT_STATUS.Approved]: "Approved",
    [BILLING_STATEMENT_STATUS.Payed]: "Payed",
    [BILLING_STATEMENT_STATUS.Rejected]: "Rejected",
}