import { objectKeysToUpperLowerCase } from "../utils/objects-util";
import { NEW_ID_KEY } from "./constants";

export function getDepotIntegrationsItems(items, types) {
    const result = (items || []).map(item=> {
        const type = types && types.length && types.find(f=> f.id === item.integrationType);
        const typeName = (type && type["name"].toFirstCharUpperCase()) || "";

        return {
            ...item,
            key: item.key === NEW_ID_KEY ? item.key : item.integrationType, 
            value: item.key === NEW_ID_KEY 
                ? ""
                : `${typeName}/${item.sourceId}`
        }
    });

    return result;
}

export function getDefaultIntegrationData(profiles, item) {
    return item
        ? {
            cityAliases: item.cityAliases,
            compensationOrderItemKey: item.compensationOrderItemKey,
            disableDelete: item.disableDelete,
            integrationType: item.integrationType,
            primary: item.primary,
            processContent: item.processContent,
            profileId: item.profileId,
            skipCodeWords: item.skipCodeWords,
            skipEmptyAddress: item.skipEmptyAddress,
            skipEmptyDate: item.skipEmptyDate,
            sourceId: item.sourceId,
            useRegistrationDate: item.useRegistrationDate
        } : {
            key: NEW_ID_KEY,
            value: "",
            cityAliases: [],
            compensationOrderItemKey: null,
            disableDelete: false,
            integrationType: 0,
            primary: false,
            processContent: false,
            profileId: (profiles && profiles[0] && profiles[0].id) || "",
            skipCodeWords: [],
            skipEmptyAddress: false,
            skipEmptyDate: false,
            sourceId: "",
            useRegistrationDate: false
        }
}

export function getServerDepotData(depot) {
    return objectKeysToUpperLowerCase({
        ...depot,
        integrations: depot.integrations.map(integration=> ({
            cityAliases: integration.cityAliases || [],
            compensationOrderItemKey: integration.compensationOrderItemKey,
            disableDelete: integration.disableDelete,
            integrationType: integration.integrationType,
            primary: integration.primary,
            processContent: integration.processContent,
            profileId: integration.profileId,
            skipCodeWords: integration.skipCodeWords || [],
            skipEmptyAddress: integration.skipEmptyAddress,
            skipEmptyDate: integration.skipEmptyDate,
            sourceId: integration.sourceId
        }))
    }, true);
}

export function getServerIntegrationData(integration) {
    return {
        CityAliases: integration.cityAliases || [],
        CompensationOrderItemKey: integration.compensationOrderItemKey,
        DisableDelete: integration.disableDelete,
        IntegrationType: integration.integrationType,
        Primary: integration.primary,
        ProcessContent: integration.processContent,
        ProfileId: integration.profileId,
        SkipCodeWords: integration.skipCodeWords || [],
        SkipEmptyAddress: integration.skipEmptyAddress,
        SkipEmptyDate: integration.skipEmptyDate,
        SourceId: integration.sourceId
    };
}
