import React from "react";
import { DebounceInput } from "react-debounce-input";
import classNames from "classnames";
import "./index.scss";

const InputControl = ({
    type, title, value, isDebounce, debounce,
    onChange, onClick
}) => {

    return (
        <div className={classNames("inputControl")}>
            {title && (<div className={classNames("title")}>{title}</div>)}
            <form autoComplete="off" className={classNames("inputItem")}>
                {isDebounce && (
                    <DebounceInput debounceTimeout={debounce || 300}
                        type={type || "text"}
                        autoComplete={"new-password"}
                        value={value || ""}
                        onChange={e=> onChange && onChange(e.target.value)}
                        onClick={e=> onClick && onClick(e)}
                    />
                )}
                {!isDebounce && (
                    <input type={type || "text"}
                        autoComplete="new-password"
                        value={value || ""}
                        onChange={e=> onChange && onChange(e.target.value)}
                        onClick={e=> onClick && onClick(e)}
                    /> 
                )}
            </form>
        </div>
    );
}

export default InputControl;