import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./index.scss";

import { WAITER_TYPES } from "../../../utils/enums";

import Dropdown from "../Dropdown";
import InputControl from "../InputControl";
import WaiterButton from "../WaiterButton";

const DropdownInput = ({ title, value, onChange, onSelect }) => {
    const [selected, setSelected] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const handleOnApply = () => {
        return new Promise((resolve, reject)=> {
            if(inputValue) {
                resolve();
                onChange(inputValue);
            } else {
                reject();
            }
        })
    }

    const handleOnSelect = item => {
        setSelected(item);
        onSelect(item);
        setInputValue(item.value);
    }

    useEffect(()=> {
        if(selected && (!Array.isArray(value) || !value.length || !value.find(f=> f.key === selected.key))) {
            setSelected(null);
            setInputValue("");
        }
    }, [value, selected, onSelect]);

    return (
        <div className={classNames("dropdownInput")}>
            <Dropdown title={title}
                value={selected && selected.key}
                values={value}
                onSelect={handleOnSelect}
            />
            <div className={classNames("inputContainer")}>
                <InputControl value={inputValue} onChange={val=> setInputValue(val)} />
                <WaiterButton type={WAITER_TYPES.icon} icon="apply" onClick={handleOnApply} />
            </div>
        </div>
    );
}

export default DropdownInput;