import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { NEW_ID_KEY } from "../../../services/constants";

import Dropdown from "../Dropdown";
import DepotIntegrationInfoItemData from "../DepotIntegrationInfoItemData";

const DepotIntegrationInfoItems = ({
    t,
    value, values, profiles, depotIntegrationTypes,
    onUpdateDepotIntegration, onSetIntegration
}) => {
    const handleOnDepotIntegratioTypeValueChange = (val, index) => {
        if(!val) {
            onSetIntegration(null, null);
        }        
    }

    useEffect(()=> {
        if(profiles && profiles.length && !value.profileId && value.key === NEW_ID_KEY) {
            onUpdateDepotIntegration({ profileId: profiles[0].id });
        }
    }, [value, profiles, onUpdateDepotIntegration]);

    return (
        <div className={classNames("depotIntegrationInfoItems")}>
            <div className={classNames("depotIntegrationInfoItemsHeader")}>
                <Dropdown title={t("depot-integration.INTEGRATION_LABEL")}
                    value={value.integrationType}
                    values={values}
                    onChange={handleOnDepotIntegratioTypeValueChange}
                    onSelect={onSetIntegration}
                />
            </div>
            <DepotIntegrationInfoItemData value={value}
                profiles={profiles}
                depotIntegrationTypes={depotIntegrationTypes}
                onUpdate={onUpdateDepotIntegration}
            />                        
        </div>
    );
}

export default withTranslation()(DepotIntegrationInfoItems);