export function objectKeysToUpperLowerCase(obj, upperCase = false) {
    if (Array.isArray(obj)) {
      return obj.map(item => objectKeysToUpperLowerCase(item, upperCase));
    } else if (typeof obj === "object" && obj) {
      let newobj = {};
      Object.keys(obj).forEach(
        key =>
          (newobj[
            upperCase ? key.toFirstCharUpperCase() : key.toFirstCharLowerCase()
          ] =
            Array.isArray(obj) || typeof obj === "object"
              ? objectKeysToUpperLowerCase(obj[key], upperCase)
              : obj[key])
      );
      return newobj;
    }
    return obj;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function isExistValue(values, propName, value) {
  return !!values.find(m => m[propName] === value);
};

export function isNullOrEmpty(value) {
  return !value && value !== 0;
}

export function isSameObjects(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function generateUniqueString(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  
  return result;
}

export const formatDateForInput = (dateToInput) => {
    const date = new Date(dateToInput);
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}