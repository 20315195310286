import React from "react";
import classNames from "classnames";
import "./index.scss";

const CheckBox = ({ title, value, onChange }) => {
    return (
        <div className={classNames("checkBox")} onClick={()=> onChange(!value)}>
            <input type="checkbox" checked={value} onChange={()=> onChange(!value)} />
            {title && (<div className={classNames("title")}>{title}</div>)}
        </div>
    );
}

export default CheckBox;