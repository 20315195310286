import axios from "axios";
import environment from "environment";

import { getAuthorization, isAuthorized } from "./auth-util";

async function defaultRequest(axiosInstance, requestConfig) {
  const request = axiosInstance.request(requestConfig);
  return await request;
}

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return error;
  }
);

export async function callServer(requestConfig) {
  if(!isAuthorized(requestConfig.roles)) {
    return {
      status: 200
    };
  }
  const headers = getHeaders();
  return await defaultRequest(axiosInstance, {
    retryCount: environment.unauthorizedRetryCount,
    ...requestConfig,
    headers: {
      ...headers,
      ...requestConfig.headers
    }
  });
}

export function getHeaders(props = null) {
  return {
    "content-type": "application/json",
    Authorization: getAuthorization(),
    ...props
  };
}

export function getErrorMessage(requestResult, customError) {
  if(!requestResult) return "Error";

  if(requestResult.response) {
    const responseError = requestResult.response.Error || requestResult.response.error;

    if(responseError) {
      return responseError;
    } else if(requestResult.response.data) {
      const { message, Message, error, Error, error_description } = requestResult.response.data;
      return (message || Message) || (`${error || Error || ""} ${error_description || ""}`);
    } else {
      return customError || "Error";
    }
  } else {
    return customError || "Error";
  }
}

export function checkResponseIsSuccessfull(response, checkData = true) {
  return response && response.status && (!checkData || response.data);
}