import React from 'react';

export const BillingProfile = ({ profile }) => {
    return (
    <div>
      <h2>Billing Profile</h2>
      <div>Name: {profile.Name}</div>
      <div>Currency: {profile.Currency}</div>
      <div>BusinessId: {profile.BusinessId}</div>
    </div>
    );
  };

  export default BillingProfile;


  
  
  