import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./index.scss";

import { checkIsAuthenticated } from "../../../utils/auth-util";

 const HomePage = ({ history }) => {
    useEffect(()=> {
        checkIsAuthenticated(history);
    }, [history]);

    return (
        <div id="homepage">
            
        </div>
    );
 };

export default withRouter(HomePage);