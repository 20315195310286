import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import "./index.scss";

import { defaultIcons } from "../../../utils/constants";
import { isAuthenticated } from "../../../utils/auth-util";

import HeaderUserMenu from "../HeaderUserMenu";
import HeaderMenu from "../HeaderMenu";

const Header = ({ location }) => {
    const [currentLocation, setCurrentLocation] = useState(location);

    useEffect(()=> {
        if(currentLocation !== location) {
            setCurrentLocation(location);
        }

    }, [currentLocation, location]);

    const logo = defaultIcons.default;

    return (
        <header id="header" className={classNames("app-header")}>
            <div className={classNames("app-header-Item")}>
                {location.pathname !== "/login" && isAuthenticated() && (<HeaderUserMenu />)}
            </div>
            <div className={classNames("app-header-Item")}>
                {location.pathname !== "/login" && isAuthenticated() && (<HeaderMenu />)}                
            </div>
            <div className={classNames("app-header-Item")}>
                {logo && (
                    <a href="/" className={classNames("logo")}>
                        <img alt="Delivr+" src={logo} />
                    </a>
                )}
                {!logo && <div className={classNames("logo", "empty")} />}
            </div>
        </header>
    )
}

export default withRouter(Header);