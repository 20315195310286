import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";

import { addCityAlias, addRegionCity, deleteCityAlias, fetchCityData } from "../../../api/restaurant-requests";
import { objectKeysToUpperLowerCase } from "../../../utils/objects-util";
import { checkResponseIsSuccessfull, getErrorMessage } from "../../../utils/request-util";

import GeoLocationInfoDataItemCombo from "../GeoLocationInfoDataItemCombo";

const GeoLocationInfoDataCity = ({ t, value, items, region, onCitySelect, onChangeCityValues }) => {
    const [cityData, setCityData] = useState(null);

    const handleOnChangeCity = val => {
        return new Promise((resolve, reject)=> {
            addRegionCity(region, val).then(response=> {
                if(checkResponseIsSuccessfull(response, false)) {
                    resolve();
                    onChangeCityValues(objectKeysToUpperLowerCase(response.data));
                } else {
                    reject();
                }
            }, error=> reject(getErrorMessage(error)));
        });
    }

    const handleOnChangeAlias = (val, isRemove) => {
        return new Promise((resolve, reject)=> {
            if(isRemove) {
                deleteCityAlias(value, val.value).then(response=> {
                    resolve();
                    if(checkResponseIsSuccessfull(response)) {
                        setCityData(objectKeysToUpperLowerCase(response.data));
                    }
                }, error=> reject(getErrorMessage(error)));
            } else {
                addCityAlias(region, value, val).then(response=> {
                    resolve();
                    if(checkResponseIsSuccessfull(response)) {
                        setCityData(objectKeysToUpperLowerCase(response.data));
                    }
                }, error=> reject(getErrorMessage(error)));
            }            
        });      
    }

    const getCityAliases = useCallback(() => {
        if(value) {
            fetchCityData(value).then(response=> {
                if(checkResponseIsSuccessfull(response)) {
                    setCityData(objectKeysToUpperLowerCase(response.data));
                }
            });
        } else {
            setCityData(null);
        }        
    }, [value]);

    useEffect(()=> {
        getCityAliases();
    }, [value, getCityAliases]);

    return (
        <GeoLocationInfoDataItemCombo title={t("geo-location.CITY_NAME_LABEL")}
            comboValues={items.map(m=> ({ key: m.id, value: m.name }))}
            listValues={((cityData && cityData.aliases) || []).map(m=> ({ key: m, value: m }))}
            onChangeItemCombo={handleOnChangeCity}
            onChangeItemList={handleOnChangeAlias}
            onSelectCombo={onCitySelect}
        />
    );
}

export default withTranslation()(GeoLocationInfoDataCity);