import GoogleMapsService from "./googleMapsService";

export function locationIsEmpty(location, isSomeEmpty = false) {
    if (!location) return true;
    const latitude = location.latitude || location.lat;
    const longitude = location.longitude || location.lng;
  
    return isSomeEmpty
      ? (!latitude || !longitude)
      : (!latitude && !longitude);
}

export function convertLocationToBaseModel(location, isRevert = false) {
    const lat = location && (location.latitude || location.lat);
    const lng = location && (location.longitude || location.lng);

    return isRevert
        ? { lat, lng }
        : { latitude: lat, longitude: lng }
}

export function convertGeometryLocation(geometryLocation) {
    return geometryLocation && {
        latitude: geometryLocation.lat(),
        longitude: geometryLocation.lng()
    }
}

export function getAddressSuggestions(searchString) {
    return new Promise((resolve, reject) => {
        GoogleMapsService.instance.getAutocompleteService().then(autocompleteService=> {
            autocompleteService.getPlacePredictions({
                //componentRestrictions: { country: countryCode },
                input: searchString,
                fields: ['name', 'geometry'],
                types: ["geocode"]
            }, (response, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && response.length) {
                    resolve(response.map(place=> ({
                        address: place.description,
                        place_id: place.place_id
                    })));
                } else {
                    return resolve([]);
                }
            }, ()=> {
                return resolve([]);
            });
        });
    });
}

export function getAddressByPlaceId(map, placeId) {
    return new Promise((resolve, reject) => {
        GoogleMapsService.instance.getPlacesService(map).then(placesService=> {
            placesService.getDetails({
                placeId,
                fields: ["name", "formatted_address", "place_id", "geometry"],
            }, (place, status) => {
                resolve(status === window.google.maps.places.PlacesServiceStatus.OK &&
                    convertGeometryLocation(place && place.geometry && place.geometry.location)
                );
            }, ()=> {
                resolve(null);
            });
        });
    });
}
