import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";

import { addCityStreet, addStreetAlias, deleteStreetAlias, fetchCityStreets, fetchStreetData, updateCityStreet } from "../../../api/restaurant-requests";
import { locationIsEmpty } from "../../../services/locations";
import { isSameObjects, objectKeysToUpperLowerCase } from "../../../utils/objects-util";
import { checkResponseIsSuccessfull, getErrorMessage } from "../../../utils/request-util";

import GeoLocationInfoDataItemCombo from "../GeoLocationInfoDataItemCombo";

const GeoLocationInfoDataStreet = ({ t, city, location, onStreetLocation, onStreetSelect }) => {    
    const [items, setItems] = useState([]);
    const [street, setStreet] = useState(null);
    const [streetData, setStreetData] = useState(null);
    const [currentCity, setCurrentCity] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);

    

    const handleOnAddNewStreet = val => {
        return new Promise((resolve, reject)=> {
            if(city && city.key) {
                addCityStreet(city.key, val).then(response=> {
                    if(checkResponseIsSuccessfull(response)) {
                        resolve();
                        setItems(items.concat([objectKeysToUpperLowerCase(response.data)]));
                    } else {
                        reject();
                    }
                }, error=> reject(getErrorMessage(error)));
            }           
        });
    }

    const handleOnChangeAlias = (val, isRemove) => {
        return new Promise((resolve, reject)=> {
            if(isRemove) {                
                deleteStreetAlias(street.key, val.value).then(response=> {
                    resolve();
                    if(checkResponseIsSuccessfull(response)) {
                        handleOnSetStreetData(objectKeysToUpperLowerCase(response.data));
                    }
                }, error=> reject(getErrorMessage(error)));
            } else {
                addStreetAlias(city.key, street.key, val).then(response=> {
                    resolve();
                    if(checkResponseIsSuccessfull(response)) {
                        handleOnSetStreetData(objectKeysToUpperLowerCase(response.data));
                    }
                }, error=> reject(getErrorMessage(error)));
            }            
        });      
    }

    const handleOnSetStreetData = useCallback(data => {
        setStreetData(data);
        onStreetSelect && onStreetSelect(data);
    }, [onStreetSelect]);
    
    const loadCityStreets = useCallback(() => {
        if(city) {
            fetchCityStreets(city.key).then(response=> {
                if(checkResponseIsSuccessfull(response)) {
                    setItems(objectKeysToUpperLowerCase(response.data));
                }
            });
        } else {
            setItems([]);
        }        
    }, [city]);

    const loadStreetData = streetId => {
        if(streetId) {
            fetchStreetData(streetId).then(response=> {
                if(checkResponseIsSuccessfull(response)) {
                    const data = objectKeysToUpperLowerCase(response.data);
                    handleOnSetStreetData(data);
                    onStreetLocation(data.location);
                }
            });
        } else {
            handleOnSetStreetData(null);
        }   
    };

    const updateStreetData = useCallback(location => {
        if(street && street.key) {            
            updateCityStreet(street.key, objectKeysToUpperLowerCase(location, true)).then(response=> {
                if(checkResponseIsSuccessfull(response)) {
                    handleOnSetStreetData(objectKeysToUpperLowerCase(response.data));
                }
            });
        }
    }, [street, handleOnSetStreetData]);

    const handleOnSelectStreet = val => {
        setStreet(val);
        loadStreetData(val.key);
    }

    useEffect(()=> {
        if(city !== currentCity) {
            setCurrentCity(city);
            loadCityStreets();
        }

        if(!isSameObjects(location, currentLocation)) {
            setCurrentLocation(location);

            if(!locationIsEmpty(location) && streetData && !isSameObjects(location, streetData.location)) {
                updateStreetData(location);
            }
        }
    }, [city, location, currentLocation, currentCity, streetData, loadCityStreets, updateStreetData]);

    return (
        <GeoLocationInfoDataItemCombo title={t("geo-location.STREET_NAME_LABEL")}
            comboValues={items.map(m=> ({ key: m.id, value: m.name }))}
            listValues={((streetData && streetData.aliases) || []).map(m=> ({ key: m, value: m }))}
            onChangeItemCombo={handleOnAddNewStreet}
            onChangeItemList={handleOnChangeAlias}
            onSelectCombo={handleOnSelectStreet}
        />
    );
}

export default withTranslation()(GeoLocationInfoDataStreet);