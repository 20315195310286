import environment from "./base";

const authOauth = "https://delivapp-west-api.azurewebsites.net/auth/";
const authAPI = "https://api.quartetsw.com/auth/api/";
const backendAPI = "https://api-staging.delivapp.com/admin/api/";
const pusherAuthEndpoint = "https://delivapp-west-api.azurewebsites.net/auth/api/pusher";
const qrserverAPI = "http://api.qrserver.com/v1/create-qr-code/";
const env = environment({
  authOauth,
  authAPI,
  backendAPI,
  pusherAuthEndpoint,
  qrserverAPI
});

const envProd = {
  ...env,
  requestLocationTime: 10000
};

export default envProd;
