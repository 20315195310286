import script from "scriptjs";
import environment from "environment";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class GoogleMapsService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error(
        "Instantiation failed: use Color.getInstance() instead of new."
      );
    }

    if (window.google && window.google.maps) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
    }
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new GoogleMapsService(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  getApiString = () => {
      return `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places&v=weekly&language=${environment.defaultLanguage}`;
  }

  getAutocompleteService = () => {
    return new Promise(resolve => {
      if (!window.google || !window.google.maps) {
        script(this.getApiString(), () => {
            this.autocompleteService = new window.google.maps.places.AutocompleteService();
            resolve(this.autocompleteService);
        });
      } else {
        if (!this.autocompleteService) {
          this.autocompleteService = new window.google.maps.places.AutocompleteService();
        }
        resolve(this.autocompleteService);
      }
    });
  };

  getPlacesService = (map) => {
    return new Promise(resolve => {
      if (!window.google || !window.google.maps) {
        script(this.getApiString(), () => {
            this.placesService = new window.google.maps.places.PlacesService(map);
            resolve(this.placesService);
        });
      } else {
        if (!this.placesService) {
          this.placesService = new window.google.maps.places.PlacesService(map);
        }
        resolve(this.placesService);
      }
    });
  };

  registerMap = () => {
    return new Promise(resolve => {
      script(this.getApiString(), () => resolve());
    });
  };
}
