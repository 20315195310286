import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import Dropdown from "../Dropdown";
import InputControl from "../InputControl";
import CheckBox from "../CheckBox";

const DepotIntegrationInfoItemData = ({
    t,
    value, profiles, depotIntegrationTypes, 
    onUpdate
}) => {
    return (
        <div className={classNames("depotIntegrationInfoItemData")}>
            <div className={classNames("depotIntegrationInfoItem")}>
                <Dropdown title={t("depot-integration.INTEGRATION_TYPE_LABEL")}
                    value={value.integrationType}
                    values={depotIntegrationTypes}
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    onSelect={item=> onUpdate({ integrationType: item.id })}
                />
            </div>
            <div className={classNames("depotIntegrationInfoItem")}>
                <InputControl title={t("depot-integration.SOURCE_ID_LABEL")} 
                    value={value.sourceId}
                    onChange={val=> onUpdate({ sourceId: val })}
                />
            </div>
            <div className={classNames("depotIntegrationInfoItem")}>
                <Dropdown title={t("depot-integration.PROFILE_LABEL")} 
                    labelField="name" 
                    valueField="id"
                    searchBy="name"
                    value={value.profileId}
                    values={profiles}
                    onSelect={item=> onUpdate({ profileId: item.name })}
                />
            </div>
            <div className={classNames("depotIntegrationInfoItem")}>
                <InputControl title={t("depot-integration.SKIP_CODE_WORD_LABEL")} 
                    value={(value.skipCodeWords || []).join(";")}
                    onChange={val=> onUpdate({ skipCodeWords: (val || []).val.split(";") })}
                />
            </div>  
            <div className={classNames("depotIntegrationInfoItem")}>
                <CheckBox title={t("depot-integration.SKIP_EMPTY_ADDRESS_LABEL")} 
                    value={value.skipEmptyAddress || ""} 
                    onChange={val=> onUpdate({ skipEmptyAddress: val })} 
                />
            </div>
            <div className={classNames("depotIntegrationInfoItem")}>
                <CheckBox title={t("depot-integration.SKIP_EMPTY_DATE_LABEL")} 
                    value={value.skipEmptyDate} 
                    onChange={val=> onUpdate({ skipEmptyDate: val })} 
                />
            </div>
            <div className={classNames("depotIntegrationInfoItem")}>
                <CheckBox title={t("depot-integration.PRIMARY_LABEL")} 
                    value={value.primary} 
                    onChange={val=> onUpdate({ primary: val })} 
                />
            </div> 
        </div>
    );
}

export default withTranslation()(DepotIntegrationInfoItemData);