import { TYPE_MENU } from "../utils/enums";

import { createHeaderMenu } from "../models/headerMenu";

export function createMenuModel(typeMenu) {
  switch (typeMenu) {
    case TYPE_MENU.header:
      return createHeaderMenu();
    default:
      return [];
  }
}
