import environment from "environment";
import { callServer } from "../utils/request-util";

export function fetchRestaurantData(restId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "depot/" + restId + "/",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchRestaurantBillingProfile(restId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/profile/' + restId, 
            method: 'GET',
        }).then(response => resolve(response), error => reject(error));
    });
}

export function fetchRestaurantBillingMenu(restId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/menu/' + restId,
            method: 'GET',
        }).then(response => resolve(response), error => reject(error));
    });
}

export function fetchBillingStatement(restId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/statements/' + restId, 
            method: 'GET',
        }).then(response => resolve(response), error => reject(error));
    });
}

export function getProductPrice(data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/productPrice',
            method: 'POST',
            data
        }).then(response => resolve(response), error => reject(error));
    });
}

export function deleteBillingStatement(id) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/' + id,
            method: 'DELETE',
        }).then(response => resolve(response), error => reject(error));
    });
}

export function approveBillingStatement(id) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/' + id + '/approve',
            method: 'PUT',
        }).then(response => resolve(response), error => reject(error));
    });
}

export function manualBillingStatement(entityId, id, payments) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/manual/' + entityId + '/' + id,
            method: 'POST',
            data: payments
        }).then(response => resolve(response), error => reject(error));
    });
}

export function createBillingStatement(data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing', 
            method: 'POST',
            data: data
        }).then(response => resolve(response), error => reject(error));
    });
}

export function updateBillingStatement(id, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + 'billing/' + id,
            method: 'PUT',
            data: data
        }).then(response => resolve(response), error => reject(error));
    });
}

export function fetchIntegrationTypes() {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "depot/integrationTypes",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function updateDepotIntegration(restId, depotId, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "depot/" + restId + "/" + depotId,
            method: "POST",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function deleteDepotIntegration(restId, depotId, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "depot/" + restId + "/" + depotId,
            method: "DELETE",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchProfiles() {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "profile",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function resetPereferialUserPassword(restId, depotId, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.authAPI + "profile/depot/peripherals/resetPassword/" + restId + "/" + depotId,
            method: "POST",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchRegions() {
    return new Promise((resolve, reject) => {
        resolve({
            status: 200,
            data: ["IL", "RU","CY"]
        });
    });
}

export function fetchRegionData(region) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/" + region,
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function updateRegionData(region, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/" + region + "/filter",
            method: "POST",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchRegionCities(region) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/" + region + "/cities",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchCityData(cityId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/city/" + cityId,
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchCityStreets(cityId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/city/" + cityId + "/street",
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function fetchStreetData(streetId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/street/" + streetId,
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function addCityStreet(cityId, name) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/city/" + cityId + "/street/" + name,
            method: "POST"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function updateCityStreet(streetId, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/street/" + streetId + "/location",
            method: "POST",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function addStreetAlias(cityId, streetId, alias) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/city/" + cityId + "/street/" + streetId + "/alias/" + alias,
            method: "POST"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function deleteStreetAlias(streetId, alias) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/street/" + streetId + "/alias/" + alias,
            method: "DELETE"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function addRegionCity(region, name) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/" + region + "/city/" + name,
            method: "POST"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function addCityAlias(region, cityId, alias) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/" + region + "/city/" + cityId + "/alias/" + alias,
            method: "POST"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function deleteCityAlias(cityId, alias) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "geobase/city/" + cityId + "/alias/" + alias,
            method: "DELETE"
        }).then(response => resolve(response), error => reject(error.response));
    });
}