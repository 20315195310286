import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import "./index.scss";

import { getCurrentUserName, logout } from "../../../utils/auth-util";

const HeaderUserMenu = ({ t, history, location }) => {    
    const [menuOpen, setMenuOpen] = useState(false);    

    const handleOnLogout = () => {
        setMenuOpen(false);
        logout();
        history.push("/login");
    }

    const userName = getCurrentUserName();

    return (
        <div className={classNames("headerUserMenu")}>
            <div className={classNames("headerUserMenuTitle")} onClick={()=> setMenuOpen(!menuOpen)}>
                {userName}
                <div className={classNames("menuArrow", { open: menuOpen })} />
            </div>
            {menuOpen && (
                <ul>
                    <li onClick={handleOnLogout}>
                        <div className={classNames("icon", "logoutIcon")} />
                        <div>{t("login.LOG_OUT_LABEL")}</div>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default withRouter(withTranslation()(HeaderUserMenu));