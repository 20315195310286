import React, {useState} from 'react';
import {WAITER_TYPES} from "../../../utils/enums";
import WaiterButton from "../WaiterButton";
import "./index.scss";
import MenuControl from "../MenuControl";
import {formatDateForInput} from "../../../utils/objects-util";

export const CreateStatementForm = ({ onSubmit, onChange, formData, isLoading, entityId, businessMenu }) => {
    const [productIndexEdit, setProductIndexEdit] = useState(null);

    const handleOnDelete = index => {
        onChange('Products', formData.Products.filter((_, i) => i !== index));
    }

    const handleOnAdd = prod => {
        onChange('Products', formData.Products.concat(prod));
    }

    const handleOnEdit = (prod, index) => {
        onChange('Products', formData.Products.map((p, i) => i === index ? prod : p ));
        setProductIndexEdit(null);
    }

    return (
        <div className={'createStatementForm'}>
            <h2>Create New Statement</h2>
            <form>
                <input
                    type="date"
                    name="StatementDate"
                    value={formData.StatementDate ? formatDateForInput(formData.StatementDate) : ''}
                    onChange={({target}) => onChange('StatementDate', target.value)}
                    placeholder="Statement Date"
                />
                <input
                    type="date"
                    name="DueDate"
                    value={formData.DueDate ? formatDateForInput(formData.DueDate) : ''}
                    onChange={({target}) => onChange('DueDate', target.value)}
                    placeholder="Due Date"
                />
                <input
                    type="text"
                    name="Price"
                    value={formData.Price}
                    onChange={({target}) => onChange('Price', target.value)}
                    placeholder="Price"
                />
                <WaiterButton
                    type={WAITER_TYPES.button}
                    title={"Create"}
                    onClick={onSubmit}
                    disabled={isLoading}
                />
            </form>

            {businessMenu && (
                <MenuControl
                    entityId={entityId}
                    menu={businessMenu}
                    products={formData.Products}
                    onDelete={handleOnDelete}
                    onAdd={handleOnAdd}
                    onEdit={handleOnEdit}
                    editIndex={productIndexEdit}
                    onCloseEdit={() => setProductIndexEdit(null)}
                />
            )}
        </div>
    );
  };

  export default CreateStatementForm;
