import { createMenuModel } from "./menuModelFactory";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Menu {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer)
      throw new Error(
        "Instantiation failed: use Menu.getInstance() instead of new."
      );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Menu(singletonEnforcer);
      return this[singleton];
    }
    return this[singleton];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  removeMenu(type) {
    this[type] = null;
  }

  getMenu(type, isRecreate) {
    if (!this[type] || isRecreate) {
      this[type] = createMenuModel(type);
    }

    return this[type];
  }
}
