import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import LoginPage from "./loginpage";
import HomePage from "./homepage";
import DepotIntegrationPage from "./depotintegrationpage";
import GeoLocationPage from "./geolocationpage";
import BillingStatementPage from "./billingPage";

class Pages extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/depotIntegration" component={DepotIntegrationPage} />
        <Route exact path="/geoLocation" component={GeoLocationPage} /> 
        <Route exact path="/billing" component={BillingStatementPage} />       
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    );
  }
}

export default Pages;
