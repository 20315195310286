import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import GoogleMapsService from "../../../services/googleMapsService";
import { convertLocationToBaseModel, locationIsEmpty } from "../../../services/locations";

import MapMarker from "../MapMarker";
import MapSearchControl from "../MapSearchControl";
import { isSameObjects } from "../../../utils/objects-util";

const MapControl = ({ t, markers, cursor, center, onClick, onMarkerClick }) => {
    const [mapCenter, setMapCenter] = useState(center || [0, 0]);
    const [mapZoom, setMapZoom] = useState(12);
    const [mapBounds, setMapBounds] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [currentMap, setCurrentMap] = useState(null);

    const handleApiLoaded = ({ map }) => {
        setCurrentMap(map);
    }

    const handleOnMapClick = clickLocation => {
        onClick && onClick(convertLocationToBaseModel(clickLocation));
    }

    const handleOnSelectSuggestedAddress = val => {
        onClick(val.location); 
        setMapCenter(convertLocationToBaseModel(val.location, true));
    }

    const handleOnSetMapBounds = val => {
        const { center, bounds, zoom } = val;

        if(!isSameObjects(center, mapCenter)) {
            setMapCenter(center);
        }

        if(!isSameObjects(zoom, mapZoom)) {
            setMapZoom(zoom);
        }
        
        if(!isSameObjects(bounds, mapBounds)) {
            setMapBounds(val);
        }
    }

    useEffect(()=> {
        GoogleMapsService.instance.registerMap().then(()=> {
            setIsRegistered(true);
        });
    }, []);

    return (
        <div className={classNames("mapControl")}>
            <MapSearchControl map={currentMap} onSelectSuggestedAddress={handleOnSelectSuggestedAddress} />
            {isRegistered && (
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    resetBoundsOnResize={true}
                    shouldUnregisterMapOnUnmount={false}
                    options={{
                        draggableCursor: cursor || "grab",
                        fullscreenControl: false
                    }}
                    zoom={mapZoom}
                    center={mapCenter}
                    bounds={mapBounds}
                    style={{ height: "100%", width: "100%" }}
                    onGoogleApiLoaded={handleApiLoaded}
                    onClick={handleOnMapClick}
                    onChange={handleOnSetMapBounds}
                >
                    {(markers || [])
                        .filter(marker=> marker && !locationIsEmpty(marker.location))
                        .map((marker, index)=> (
                            <MapMarker key={`markerKey-${marker.id || index}`}
                                id={`marker-${marker.id || index}`} 
                                icon={marker.icon.url}
                                lat={marker.location.latitude}
                                lng={marker.location.longitude}
                                type={marker.type}
                                onClick={()=> onMarkerClick && onMarkerClick(marker.location)}
                            />
                    ))}
                </GoogleMapReact>
            )}
        </div>
    );
}

export default withTranslation()(MapControl);