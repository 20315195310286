import Modal from "react-modal";
import StatementPayments from "../StatementPayments";
import {IconClose} from "delivapp-ordering";
import './index.scss';


const StatementPaymentsModal = ({isOpen, onClose, ...props}) => {
    return (
        <Modal isOpen={Boolean(isOpen)} className={'statementPaymentsModal'}>
            <div className={'statementPaymentsModalContent'}>
                <IconClose size={'small'} onClick={onClose}/>
                <StatementPayments {...props}/>
            </div>
        </Modal>
    )
}

export default StatementPaymentsModal;