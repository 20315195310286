function firstLower() {
    Object.assign(String.prototype, {
      toFirstCharLowerCase() {
        return `${this.charAt(0).toLowerCase()}${this.slice(1)}`;
      }
    });
  }
  
  function firstUpper() {
    Object.assign(String.prototype, {
      toFirstCharUpperCase() {
        return `${this.charAt(0).toUpperCase()}${this.slice(1)}`;
      }
    });
  }
  
  export default function mainFunction() {
    firstLower();
    firstUpper();
  }
  