import React from "react";
import Modal from "react-modal";
import CreateStatementForm from "../BillingStatement";
import './index.scss';
import {IconClose} from "delivapp-ordering";

const CreateStatementFormModal = ({entityId, formData, isLoading, onChange, onSubmit, businessMenu, isOpen, onClose}) => {
    return (
        <Modal isOpen={Boolean(isOpen)} className={'createStatementFormModal'}>
            <div className={'createStatementFormModalContent'}>
                <IconClose size={'small'} onClick={onClose}/>
                <CreateStatementForm
                    entityId={entityId}
                    businessMenu={businessMenu}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    formData={formData}
                    isLoading={isLoading}
                />
            </div>
        </Modal>
    )
}

export default CreateStatementFormModal;