import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import "./index.scss";

import { useOnClickOutside } from "../../../utils/hooks";
import { isExistValue } from "../../../utils/objects-util";

import InputControl from "../InputControl";

const Dropdown = ({
    values, value, title,
    labelField = "value", valueField = "key", searchBy = "value", deleted,
    onSelect, onChange
}) => {
    const dropdownRef = useRef();
    const [open, setOpen] = useState(false);
    const [searchString, setSearchString] = useState("");

    const clickOutside = () => {
        setOpen(false);
    }

    const handleOnSearchChange = val => {
        setSearchString(val);
        
        if(val && !open) {
            setOpen(true);
        }
        
        onChange && onChange(val);
    }

    const handleOnSelect = (item, index) => {
        onSelect(item, index);
        setSearchString(item[labelField]);
        setOpen(false);
    }

    useOnClickOutside(dropdownRef, clickOutside);

    useEffect(()=> {
        if((value || value === 0) && values.length) {
            if(!searchString) {
                const item = (values || []).find(f=> f[valueField].toString() === value.toString());
                if(item) setSearchString(item[labelField]);
            }            
        } else {
            if(((!value && value !== 0) || !values.length) && searchString) {
                setSearchString("");
            }
        }
    }, [values, value, searchString, valueField, labelField]);
    
    const items = searchString && !isExistValue(values || [], searchBy, searchString)
        ? (values || []).filter(item => (item[searchBy] || "").toString().toLowerCase().includes(searchString))
        : (values || []);

    return (
        <div ref={dropdownRef} className={classNames("dropdown")}>
            {title && (<div className={classNames("title")}>{title}</div>)}
            <div className={classNames("dropdownContainer")}>
                <InputControl value={searchString} 
                    onClick={()=> setOpen(!open)}
                    onChange={handleOnSearchChange}
                />
                <div className={classNames("icon", { open: open })}></div>
                {open && (
                    <ul>
                        {items.map((item, index)=> (
                                <li key={`dropdownItem-${index}`} 
                                    className={classNames("overflowHiddenText", { selected: item[valueField] === value, deleted: deleted && deleted(item) })} 
                                    onClick={()=> handleOnSelect(item, index)}
                                >
                                    {item[labelField]}
                                </li>
                            ))
                        }
                    </ul>
                )}
            </div>            
        </div>
    );
}

export default Dropdown;