import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./index.scss";

import { checkIsAuthenticated } from "../../../utils/auth-util";

import GeoLocationInfo from "../../components/GeoLocationInfo";

 const GeoLocationPage = ({ history }) => {
    useEffect(()=> {
        checkIsAuthenticated(history);
    }, [history]);

    return (
        <div id="geolocationpage">
            <GeoLocationInfo />
        </div>
    );
 };

export default withRouter(GeoLocationPage);