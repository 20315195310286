import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { DebounceInput } from "react-debounce-input";
import classNames from "classnames";
import "./index.scss";

const SearchBox = ({ useValue, placeholder, isSearchByEnter, shortCuts, onChange }) => {
  const [searchString, setSearchString] = useState(useValue || "");
  const handleOnSearchChange = (value, isClear) => {
    setSearchString(value);
    if(!isSearchByEnter || isClear) onChange(value);
  };

  const handleOnKeyDown = e => {
    if(e.keyCode === 13) {
      e.preventDefault();
      handleOnClick();
    }
  }

  const handleOnClick = () => {
    onChange(searchString);    
  }

  useEffect(() => {
    if(searchString !== useValue && useValue !== undefined){
      setSearchString(useValue);
    }
  }, [useValue, searchString]);

  return (
    <div className={classNames("searchBox")}>
      <div className={classNames("searchBoxContainer")}>        
        <form autoComplete="new-password">
          <DebounceInput debounceTimeout={200}
            type={"text"}
            autoComplete={"new-password"}
            value={searchString}
            placeholder={placeholder}
            className={classNames("searchBoxInput")}          
            onKeyDown={handleOnKeyDown}
            onChange={e => handleOnSearchChange(e.target.value)}
          />
        </form>
        <div className={classNames("searchBoxIcon")} onClick={handleOnClick}></div>        
        {searchString && (
          <div className={classNames("clearSearch")} onClick={() => handleOnSearchChange("", true)} />
        )}
      </div>
      {shortCuts && shortCuts.length > 0 && (
        <div className={classNames("searchBoxFilters")}>
          {shortCuts.map(shortCut=> (
            <div key={`searchBoxFilter-${shortCut}`} className={classNames("filterItem", { selected: shortCut === searchString })} 
              onClick={()=> handleOnSearchChange(shortCut === searchString ? "" : shortCut)}
            >
              {shortCut}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SearchBox);
