import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { fetchIntegrationTypes, fetchProfiles, fetchRestaurantData, resetPereferialUserPassword } from "../../../api/restaurant-requests";
import { checkResponseIsSuccessfull, getErrorMessage } from "../../../utils/request-util";
import { objectKeysToUpperLowerCase } from "../../../utils/objects-util";
import { WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";

import InputControl from "../InputControl";
import Dropdown from "../Dropdown";
import WaiterButton from "../WaiterButton";
import DepotIntegrationInfo from "../DepotIntegrationInfo";
import DepotPereferialUser from "../DepotPereferialUser";

const DepotIntegration = ({ t }) => {
    const [search, setSearch] = useState("");
    const [depot, setDepot] = useState(null);
    const [depots, setDepots] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [depotIntegrationTypes, setDepotIntegrationTypes] = useState([]);    

    const handleOnSearchChange = val => {
        setSearch(val);
    }

    const getRestaurantData = () => {
        return new Promise((resolve, reject)=> {
            if(search) {
                fetchRestaurantData(search).then(response=> {
                    if(checkResponseIsSuccessfull(response)) {
                        const depots = objectKeysToUpperLowerCase(response.data);
                        setDepots(depots);
                        setDefaultDataUfterUpdate(depots);
                        updateProfiles();
                        updateDepotIntegrationTypes();
                        resolve();
                    } else {
                        reject(getErrorMessage(response));
                    }
                }, error=> reject(getErrorMessage(error)))
            } else {
                reject();
            }            
        });
    }

    const updateProfiles = () => {
        fetchProfiles().then(response=> {
            if(checkResponseIsSuccessfull(response)) {
                const profiles = objectKeysToUpperLowerCase(response.data);
                setProfiles(profiles);
            }
        });
    }

    const updateDepotIntegrationTypes = () => {
        fetchIntegrationTypes().then(response=> {
            if(checkResponseIsSuccessfull(response)) {
                setDepotIntegrationTypes(objectKeysToUpperLowerCase(response.data));
            }
        });
    }

    const setDefaultDataUfterUpdate = data => {
        if(data && data.length) {
            setDepot(data[0]);              
        }
    }

    const handleOnChangeDepot = (val, isRemove) => {
        setDepots(isRemove 
            ? depots.filter(f=> f.id !== val.id)
            : depots.map(m=> m.id === val.id ? val : m)
        );

        setDepot(isRemove ? depots[0] : val);
    }

    const handleOnPereferialUserUpdate = data => {
        return new Promise((resolve, reject)=> {
            resolve();

            if(search && depot && data.userName && data.password) {
                resetPereferialUserPassword(search, depot.id, objectKeysToUpperLowerCase(data, true)).then(response=> {
                    if(checkResponseIsSuccessfull(response)) {
                        resolve();
                    } else {
                        reject(getErrorMessage(response));
                    }
                }, error=> {
                    reject(getErrorMessage(error));
                });
            } else {
                reject();
            }
        });
    }

    return (
        <div className={classNames("depotIntegration")}>
            <div className={classNames("searchContainer")}>
                <InputControl title={t("depot-integration.SEARCH_RESTAURANT_LABEL")}
                    value={search}
                    onChange={handleOnSearchChange}
                />
                <WaiterButton type={WAITER_TYPES.button} 
                    typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm} 
                    title={t("basic.LOAD_BUTTON")} 
                    onClick={getRestaurantData} 
                />
            </div>
            <div className={classNames("dataContainer")}>
                {depots.length > 0 && (
                    <Dropdown title={t("depot-integration.DEPOT_LABEL")}
                        value={depot && depot.id}
                        values={depots || []}
                        labelField="name"
                        valueField="id"
                        searchBy="name"
                        deleted={item=> item.isDeleted}
                        onSelect={item=> setDepot(item)}
                    />
                )}
                {depot && depotIntegrationTypes.length > 0 && (
                    <DepotIntegrationInfo value={depot} 
                        restId={search} 
                        profiles={profiles} 
                        depotIntegrationTypes={depotIntegrationTypes}
                        onChange={handleOnChangeDepot} 
                    />
                )}
                {depot && (<DepotPereferialUser onClick={handleOnPereferialUserUpdate} />)}
            </div>            
        </div>
    );
}

export default withTranslation()(DepotIntegration);