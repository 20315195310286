function baseEnvironment(args) {
  return {
    pusherAppKey: "e739a7a0766fbd613805",
    pusherCluster: "eu",
    googleApiKey: "AIzaSyDKBAemLLmlG-f2zo12hfy5FnGEtSvp244",
    freshChatToken: "5ff57c51-b466-4409-b707-b0d0a98e01d6",
    clientId: "B2EBEBA177EB44AAA0648F67D876A7DB",
    sentryDsn: "https://b808035e49c142858d1c6a282f620dd9@o431146.ingest.sentry.io/5381331",
    defaultLanguage: "EN",
    targetTime: {
      minutes: [0, 15, 30, 45]
    },    
    unauthorizedRetryCount: 3,
    checkConnectionTime: 10000,
    requestLocationTime: 60000,
    requestAudioTime: 10000,
    courierComingNotificationTime: 900000,
    dataExpiresTime: 86400,
    dataExpiresTimeShort: 7200,
    dataExpiresTimePicture: 604800,
    dataExpiresTimePictureShort: 172800,
    intervalNotification: 7000,
    restaurant: {
      id: "1",
      image: "assets/images/markers_icons/base.png"
    },
    token: {
      lifecookies: 720
    },
    orders: {
      defaultHistoryTime: 3,
      minHistoryTime: 0,
      maxHistoryTime: 24,
      imageUrl: "assets/images/markers_icons/",
      imageExtension: ".png",
      notAssociated: "white.png",
      maxOrdersPerPage: 7,
      maxSymbolsInOrderName: 3,
      maxOrdersInTitle: 3,
      readinessTimeIntervals: [20, 15, 10]
    },
    AUTH_EVENTS: {
      loginSuccess: "auth-login-success",
      loginFailed: "auth-login-failed",
      logoutSuccess: "auth-logout-success",
      sessionTimeout: "auth-session-timeout",
      notAuthenticated: "auth-not-authenticated",
      notAuthorized: "auth-not-authorized"
    },
    USER_ROLES: {
      all: "*",
      supervisor: "Supervisor",
      deliveryManager: "DeliveryManager",
      depot: "Depot",
      courier: "Courier",
      supportOperator: "SupportOperator"
    },
    defaultUserUrl: {
      all: "/",
      deliveryManager: "/dashboard",
      depot: "/orders",
      supervisor: "/controlCenters",
      inactive: "/shifts"
    },
    groups: {
      colors: [
        "blue-g",
        "brown-g",
        "green-g",
        "lightgreen-g",
        "pink-g",
        "red-g",
        "turquoise-g",
        "violet-g",
        "yellow-g"
      ]
    },
    ...args
  };
}

export default baseEnvironment;
