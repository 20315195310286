import React from "react";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

const LoaderControl = ({ t, size = 100 }) => (
    <div className={classNames("loaderControl")}>
        <div className={classNames("title")}>{t("basic.LOADING_LABEL")}</div>
        <Loader type="Bars" color="#007aff" height={size} width={size} />
    </div>
)

export default withTranslation()(LoaderControl);