import React, {useState} from 'react';
import './style.scss';
import WaiterButton from "../WaiterButton";
import {WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES} from "../../../utils/enums";

const StatementPayments = ({payments, onSave}) => {
    const [newPayments, setNewPayments] = useState([]);
    const handleOnAddNewPayment = () => {
        setNewPayments(newPayments.concat({ Id: Date.now().toString(), Price: '' }));
        return Promise.resolve();
    }

    const handleOnChange = (id, price) => {
        setNewPayments(newPayments.map(p => p.Id === id ? {...p, Price: price} : p));
    }

    const handleOnSavePayments = async () => {
        onSave(newPayments
            .map(p => ({ Id: p.Id, Price: parseFloat(p.Price) }))
            .filter(p => p.Price)
        );
        return;
    }

    return (
        <div className={'statementPayments'}>
            <span>Select Payment</span>
            <div className={'paymentContent'}>
                {(payments || []).map((p => (
                    <div className={'paymentItem'} key={p.Id}>
                        <span>Paid</span>
                        <span>{p.price}</span>
                    </div>
                )))}
                {newPayments.map((p => (
                    <div className={'paymentItem'} key={p.Id}>
                        <span>Paid</span>
                        <input
                            type="number"
                            value={p.Price}
                            onChange={({target}) => handleOnChange(p.Id, target.value)}
                        />
                    </div>
                )))}
                <div className={'paymentItem addPayment'} onClick={handleOnAddNewPayment}>Add Payment +</div>
            </div>

            <div className={'buttons'}>
                <WaiterButton
                    type={WAITER_TYPES.button}
                    typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                    title={"Save"}
                    onClick={handleOnSavePayments}
                />
            </div>
        </div>
    )
}

export default StatementPayments;