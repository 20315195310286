import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./index.scss";

import { deleteDepotIntegration, updateDepotIntegration } from "../../../api/restaurant-requests";
import { getDefaultIntegrationData, getDepotIntegrationsItems, getServerIntegrationData } from "../../../services/restaurant";
import { checkResponseIsSuccessfull } from "../../../utils/request-util";
import { isNullOrEmpty, isSameObjects } from "../../../utils/objects-util";
import { NEW_ID_KEY } from "../../../services/constants";

import DepotIntegrationInfoItems from "../DepotIntegrationInfoItems";
import DepotIntegrationInfoControls from "../DepotIntegrationInfoControls";


const DepotIntegrationInfo = ({ value, restId, profiles, depotIntegrationTypes, onChange }) => {
    const getValue = data => ({ 
        ...(data || value), 
        integrations: getDepotIntegrationsItems((data || value).integrations, depotIntegrationTypes) 
    });

    const [currentValue, setCurrentValue] = useState(getValue(value));
    const [integrationIndex, setIntegrationIndex] = useState(0);
    const integrationValue = (isNullOrEmpty(integrationIndex) || !currentValue || !currentValue.integrations.length || !currentValue.integrations[integrationIndex])
        ? currentValue.integrations.find(f=> f.key === NEW_ID_KEY) || getDefaultIntegrationData()
        : currentValue.integrations[integrationIndex];

    const isEdited = () => {
        if(!currentValue) return false;    

        const resWithId = !isSameObjects({ 
            ...value, 
            integrations: getDepotIntegrationsItems(value.integrations.concat([{ ...getDefaultIntegrationData(profiles) }]), depotIntegrationTypes)
                .map(m=> m.key === NEW_ID_KEY
                    ? { ...m, value: "" }
                    : m
                )
        }, currentValue);

        return currentValue.integrations.find(f=> f.key === NEW_ID_KEY)
            ? resWithId
            : !isSameObjects(currentValue, getValue());
    }

    const handleOnUpdateDepotIntegration = propValue => {
        
        const newValue = {
            ...currentValue,
            integrations: getDepotIntegrationsItems(currentValue.integrations.find(f=> f.key === integrationValue.key)
                ? currentValue.integrations
                    .map(integration=> integration.key === integrationValue.key
                        ? { ...integration, ...propValue }
                        : integration
                    )
                : currentValue.integrations
                    .concat([{
                        ...getDefaultIntegrationData(),
                        ...propValue
                    }])
            , depotIntegrationTypes)
        };

        setCurrentValue(newValue);
    }

    const handleOnSetIntegration = (val, index) => {
        setIntegrationIndex(isNullOrEmpty(index) ? null : index);
    }

    const handleOnSave = () => {
        return new Promise((resolve, reject)=> {
            resolve();
            const editedValue = isNullOrEmpty(integrationIndex)
                ? currentValue.integrations.find(f=> f.key === NEW_ID_KEY)
                : currentValue.integrations[integrationIndex];
                
            updateDepotIntegration(restId, currentValue.id, getServerIntegrationData(editedValue)).then(response=> {
                if(checkResponseIsSuccessfull(response, false)) {
                    resolve();
                    const newValue = {
                        ...currentValue,
                        integrations: editedValue.key === NEW_ID_KEY
                            ? value.integrations.concat([getDefaultIntegrationData(editedValue, true)])
                            : value.integrations.map((integration, index)=> index === integrationIndex
                                ? getDefaultIntegrationData(null, editedValue)
                                : integration
                            )
                    };

                    onChange(newValue);
                    setCurrentValue(getValue(newValue));
                } else {
                    reject();
                }
            });
        });
    }

    const handleOnDelete = () => {
        return new Promise((resolve, reject)=> {
            const integration = currentValue.integrations[integrationIndex];

            if(integration && integration.key !== NEW_ID_KEY) {
                const data = {
                    IntegrationType: integration.integrationType,
                    SourceId: integration.sourceId
                };
                
                deleteDepotIntegration(restId, currentValue.id, data).then(response=> {
                    if(checkResponseIsSuccessfull(response, false)) {
                        resolve();

                        const newValue = { 
                            ...value, 
                            integrations: value.integrations
                                .filter(f=> f.integrationType !== integration.integrationType && f.sourceId !== integration.sourceId)
                        };

                        onChange(newValue);
                        setCurrentValue(getValue(newValue));
                    } else {
                        reject();
                    }
                });
            } else {
                reject();
            }
        });
    }

    const handleOnCancel = () => {
        return new Promise(resolve=> {
            resolve();
            if(value !== currentValue) {
                setCurrentValue(getValue());
                setIntegrationIndex(0);
            }
        });
    }

    useEffect(()=> {
        if(value !== currentValue && value && currentValue && value.id !== currentValue.id) {
            setCurrentValue({ 
                ...value, 
                integrations: getDepotIntegrationsItems(value.integrations, depotIntegrationTypes) 
            });
            setIntegrationIndex(0);
        }
    }, [value, currentValue, integrationIndex, depotIntegrationTypes]);

    return (
        <div className={classNames("depotIntegrationInfo")}>
            <DepotIntegrationInfoItems 
                value={integrationValue} 
                values={currentValue.integrations}
                profiles={profiles}
                depotIntegrationTypes={depotIntegrationTypes}
                onUpdateDepotIntegration={handleOnUpdateDepotIntegration}
                onSetIntegration={handleOnSetIntegration}
            />
            <DepotIntegrationInfoControls 
                isEdited={isEdited()}
                onSave={handleOnSave} 
                onDelete={handleOnDelete} 
                onCancel={handleOnCancel} 
            />
        </div>
    );
}

export default DepotIntegrationInfo;