import environment from "environment";
import qs from "qs";

import { getRefreshToken } from "../utils/auth-util";
import { callServer } from "../utils/request-util";

export function authorize(username, password, skipRetry) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.authOauth + "oauth2/token",
      method: "POST",
      headers: getHeaders(),
      data: qs.stringify({
        username,
        password,
        grant_type: "password",
        client_id: environment.clientId
      }),
      skipRetry
    }).then(response => resolve(response), error => reject(error));
  });
}

export function refreshAuth() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.authOauth + "oauth2/token",
      method: "POST",
      headers: getHeaders(),
      data: qs.stringify({
        client_id: environment.clientId,
        grant_type: "refresh_token",
        refresh_token: getRefreshToken()
      })
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getHeaders() {
  return {
    "content-type": "application/x-www-form-urlencoded",
    "Referer" : "https://admin.delivapp.com/"
  };
}
