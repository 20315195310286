import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./index.scss";

import { checkIsAuthenticated } from "../../../utils/auth-util";

import BillingComponent from '../../components/BillingComponent';

const BillingStatementPage = ({ history }) => {
    useEffect(() => {
      checkIsAuthenticated(history);
    }, [history]);
  
    return (
      <div id="billingStatementPage">
        <BillingComponent />
      </div>
    );
  };

export default withRouter(BillingStatementPage);
