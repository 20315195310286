import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";
import "./index.scss";

import preloader from "../../../assets/images/preloader.GIF";

import { WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";

const WaiterButton = ({
    type,
    title,
    disabled,
    typeStyle = WAITER_BUTTON_TYPE_STYLE.default,
    icon,
    onClick,
    className
}) => {
    const hoverRef = useRef();
    const [isShake, setShake] = useState(false);
    const [processing, setProcessing] = useState(false);

    const isDisabled = disabled || processing;

    const handleOnClick = () => {
        return new Promise((resolve, reject)=> {
            if (isDisabled) return;

            if(!onClick) {
                reject();
            }

            setProcessing(true); 
            onClick().then(()=> {
                //Fix unmounted component error
                if(hoverRef && hoverRef.current) {
                    setProcessing(false);
                }
            }, errorMessage=> {
                setProcessing(false);
                setShake(true);
                setTimeout(() => {
                    setShake(false);
                    if (errorMessage) {
                        toast.error(errorMessage);
                    }
                }, 500);
            })
        });
    }
    
    return (
        <div ref={hoverRef} className={classNames("waiterButton", className)}>
            {type === WAITER_TYPES.button && (
                <button type="submit"
                    className={classNames(typeStyle, { disabled: isDisabled, shake: isShake })}
                    disabled={disabled}
                    onClick={handleOnClick}
                >
                    <span>{title}</span>
                    {processing && <img src={preloader} alt="preloader" />}
                </button>
            )}
            {type === WAITER_TYPES.icon && (
                <div className={classNames("iconWaiter", icon, { disabled: isDisabled, shake: isShake })} 
                    onClick={handleOnClick}
                >
                    {processing && <img src={preloader} alt="preloader" />}
                </div>
            )}
        </div>
    );
}

export default WaiterButton;