import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

import {
  approveBillingStatement,
  createBillingStatement, deleteBillingStatement,
  fetchBillingStatement,
  fetchRestaurantBillingMenu,
  fetchRestaurantBillingProfile, manualBillingStatement, updateBillingStatement
} from "../../../api/restaurant-requests";
import BillingProfile from '../BillingProfile';
import BillingStatementsList from '../BillingStatementList';
import CreateStatementForm from '../BillingStatement/CreateStatement';

import WaiterButton from "../WaiterButton";
import InputControl from "../InputControl";

import { WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";
import {checkResponseIsSuccessfull, getErrorMessage} from "../../../utils/request-util";
import {toast} from "react-toastify";
import CreateStatementFormModal from "../CreateStatementFormModal";

const BillingComponent = () => {
  const { t } = useTranslation();

  const [entityId, setEntityId] = useState('michelin');
  const [billingProfile, setBillingProfile] = useState(null);
  const [businessMenu, setBusinessMenu] = useState(null);
  const [statements, setStatements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statementEditFormData, setStatementEditFormData] = useState(null);
  const [statementNewFormData, setStatementNewFormData] = useState({
    StatementDate: '',
    DueDate: '',
    Price: '',
    Products: [],
  });

  const handleSearch = async () => {
    setIsLoading(true);
    const profile = await fetchRestaurantBillingProfile(entityId);
    const [statementsData, menu] = await Promise.allSettled([
      fetchBillingStatement(entityId),
      fetchRestaurantBillingMenu("5jh3g1b9" /*profile.data.BusinessId*/),
    ]);
    if(checkResponseIsSuccessfull(profile)) {
      setBillingProfile(profile.data);
    }
    if(statementsData.status === "fulfilled" && statementsData.value?.status === 200) {
      setStatements(statementsData.value.data);
    } else {
      toast.error("Statements error: " + statementsData?.value?.response?.data?.Message || "Error");
    }
    if(menu.status === "fulfilled" && menu.value?.status === 200) {
      setBusinessMenu(menu.value.data);
    } else {
      toast.error("Menu error: " + menu?.value?.response?.data?.Message || "Error");
    }
    setIsLoading(false);
  };

  const handleNewStatementChange = (key, value) => setStatementNewFormData({
    ...statementNewFormData,
    [key]: value
  });

  const handleEditStatementChange = (key, value) => setStatementEditFormData({
    ...statementEditFormData,
    [key]: value
  });

  const handleSubmitNewStatement = async () => {
    setIsLoading(true);
    const createdStatementResponse = await createBillingStatement({
      StatementDate: new Date(statementNewFormData.StatementDate),
      DueDate: new Date(statementNewFormData.DueDate),
      Price: parseFloat(statementNewFormData.Price) || 0,
      Products: statementNewFormData.Products,
      EntityId: entityId,
    });

    if(!checkResponseIsSuccessfull(createdStatementResponse)) {
      toast.error(getErrorMessage(createdStatementResponse));
      setIsLoading(false);
      return;
    }
    setStatements([...statements, createdStatementResponse.data]);
    setStatementNewFormData({ StatementDate: '', DueDate: '', Price: '', Products: [] });
    setIsLoading(false);
  };

  const handleOnSubmitEditStatement = async () => {
    setIsLoading(true);
    const createdStatementResponse = await updateBillingStatement(statementEditFormData.Id, {
      StatementDate: new Date(statementEditFormData.StatementDate),
      DueDate: new Date(statementEditFormData.DueDate),
      Price: parseFloat(statementEditFormData.Price) || 0,
      Products: statementEditFormData.Products,
      EntityId: entityId,
    });

    if(!checkResponseIsSuccessfull(createdStatementResponse)) {
      toast.error(getErrorMessage(createdStatementResponse));
      setIsLoading(false);
      return;
    }
    setStatements(statements.map(s => s.Id === createdStatementResponse.data.Id ? createdStatementResponse.data : s));
    setStatementEditFormData(null);
    setIsLoading(false);
  }

  const handleOnDeleteStatement = async ({Id}) => {
    setIsLoading(true);
    const deleteStatement = await deleteBillingStatement(Id);
    if(!checkResponseIsSuccessfull(deleteStatement)) {
      toast.error(getErrorMessage(deleteStatement));
      setIsLoading(false);
      return;
    }

    setStatements(statements => statements.map(s => s.Id === Id ? deleteStatement.data : s));
    setIsLoading(false);
  }

  const handleOnApproveStatement = async ({Id}) => {
    setIsLoading(true);
    const approveStatement = await approveBillingStatement(Id);
    if(!checkResponseIsSuccessfull(approveStatement)) {
      toast.error(getErrorMessage(approveStatement));
      setIsLoading(false);
      return;
    }

    setStatements(statements => statements.map(s => s.Id === Id ? approveStatement.data : s));
    setIsLoading(false);
  }

  const handleOnEditStatement = (statement) => {
    setStatementEditFormData({
      Products: statement.Products,
      StatementDate: statement.StatementDate,
      DueDate: statement.DueDate,
      Price: statement.Price,
      Id: statement.Id
    });
  }

  const handleOnManual = async ({Id}, payments) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      const manualStatement = await manualBillingStatement(entityId, Id, payments);
      if(!checkResponseIsSuccessfull(manualStatement)) {
        toast.error(getErrorMessage(manualStatement));
        setIsLoading(false);
        reject();
        return;
      }

      setStatements(statements => statements.map(s => s.Id === Id ? manualStatement.data : s));
      setIsLoading(false);
      resolve();
    })
  }

  return (
    <div className={'billingComponent'}>
      <div className={'loadControl'}>
        <InputControl
            title={t("depot-integration.SEARCH_RESTAURANT_LABEL")}
            value={entityId}
            onChange={setEntityId}
        />

        <WaiterButton
            type={WAITER_TYPES.button}
            typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
            title={t("basic.LOAD_BUTTON")}
            onClick={handleSearch}
        />
      </div>

      {isLoading && <p>Loading...</p>}
      {billingProfile && (<BillingProfile profile={billingProfile} />)}
      <CreateStatementForm
          entityId={entityId}
          businessMenu={businessMenu}
          onSubmit={handleSubmitNewStatement}
          onChange={handleNewStatementChange}
          formData={statementNewFormData}
          isLoading={isLoading}
      />
      <CreateStatementFormModal
          isOpen={statementEditFormData}
          onClose={() => setStatementEditFormData(null)}
          entityId={entityId}
          businessMenu={businessMenu}
          onSubmit={handleOnSubmitEditStatement}
          onChange={handleEditStatementChange}
          formData={statementEditFormData || {}}
          isLoading={isLoading}
      />
      <BillingStatementsList
          statements={statements}
          onDelete={handleOnDeleteStatement}
          onEdit={handleOnEditStatement}
          onApprove={handleOnApproveStatement}
          onManual={handleOnManual}
      />
    </div>
  );
};

export default BillingComponent;
