import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { fetchRegions } from "../../../api/restaurant-requests";
import { checkResponseIsSuccessfull } from "../../../utils/request-util";

import Dropdown from "../Dropdown";
import GeoLocationInfoData from "../GeoLocationInfoData";

const GeoLocationInfo = ({ t }) => {
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(null);

    const loadRegions = useCallback(() => {
        fetchRegions().then(response=> {
            if(checkResponseIsSuccessfull(response)) {
                setRegions(response.data.map(m=> ({ key: m, value: m })));
            }
        });
    }, []);

    const loadData = useCallback(() => {
        loadRegions();
    }, [loadRegions]);

    useEffect(()=> {
        loadData();
    }, [loadData]);

    return (
        <div className={classNames("geoLocationInfo")}>
            <div className={classNames("geoLocationInfoHeader")}>
                <Dropdown title={t("geo-location.REGIONS_LABEL")}
                    value={region && region.key}
                    values={regions}
                    onSelect={item=> setRegion(item)}
                />
            </div>
            <GeoLocationInfoData value={region && region.key} />
        </div>
    );
}

export default withTranslation()(GeoLocationInfo);