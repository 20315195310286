import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {
    HALF_OPTION,
    prepareHalfOptions,
    ProductConstructor,
    mergeProduct,
    checkRespectTo,
    IconClose
} from "delivapp-ordering";
import classnames from "classnames";
import {getProductPrice} from "../../../api/restaurant-requests";
import './style.scss';

const ProductConstructorModal = ({entityId, isOpen, productEdit, handleOnAddToCart, onClose, product, currency, business_id}) => {
    const [comments, setComments] = useState("");
    const [nameOnBox, setNameOnBox] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [isHalf, setIsHalf] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsHalf, setOptionsHalf] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [productForSave, setProductForSave] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        const data = {
            business_id: business_id,
            entity_id: entityId,
            product: {
                id: product.id,
                quantity: quantity,
                name: product.name,
                ingredients: ingredients ? ingredients.filter(el => !el.quantity).map(el => el.id) : [],
                price: product.price,
                full_price: product.full_price,
                name_on_box: nameOnBox,
                comment: comments,
                options: [].concat(getServerModelOptions(options)).concat(getServerModelOptions(optionsHalf))
            }
        }

        // setProductForSave(data);
        getProductPrice(data).then(response => {
            if(response.status === 200) {
                setTotalPrice(response.data.full_price);
                setProductForSave(response.data);
            }
        }).finally(() => {
            setIsLoading(false);
        })
        // eslint-disable-next-line
    }, [options, optionsHalf, quantity]);


    useEffect(() => {
        const allOptions = product.extras.filter(ex => ex.enabled).flatMap(ex => ex.options).filter(op => op.enabled).map(op => ({
            ...op,
            half: op.half,
            respectTo: op.respect_to,
            withHalfOption: op.with_half_option,
            limitSuboptionsByMax: op.limit_suboptions_by_max,
            allowSuboptionQuantity: op.allow_suboption_quantity,
        }));
        if(productEdit) {
            const mergedData = mergeProduct(productEdit, allOptions, ingredients);
            setComments(mergedData.comments);
            setNameOnBox(mergedData.nameOnBox);
            setQuantity(mergedData.quantity);
            setIsHalf(mergedData.isHalf);
            setOptions(mergedData.options.filter(op => !op.with_half_option));
            setOptionsHalf(mergedData.optionsHalf.filter(op => op.with_half_option));
            setIngredients(mergedData.ingredients.map(ing => ({
                name: ing.name, quantity: 1, id: ing.id,
            })));
        } else {
            const optionsFull = allOptions.filter(op => !op.with_half_option);
            const optionsHalf = allOptions.filter(op => op.with_half_option).map(op => ({
                ...op,
                half: HALF_OPTION.none,
                respectTo: op.respect_to,
                withHalfOption: op.with_half_option,
                limitSuboptionsByMax: op.limit_suboptions_by_max,
                allowSuboptionQuantity: op.allow_suboption_quantity,
            }));
            setOptions(optionsFull);
            setOptionsHalf(optionsHalf);
            const ingredients = (product.ingredients || []).map(ing => ({
                name: ing.name, quantity: 1, id: ing.id,
            }));
            setIngredients(ingredients);
        }
        // eslint-disable-next-line
    }, []);

    const handleOnChangeOption = (parentId, id, quantity) => {
        const value = quantity <= 0 ? 0 : quantity;
        const option = options.find(opt => opt.id === parentId);
        if(option.max === 1 && option.min === 1) {
            option.suboptions = option.suboptions.map(sub => sub.id === id ? {...sub, quantity: 1} : {...sub, quantity: 0});
        } else {
            option.suboptions = option.suboptions.map(sub => sub.id === id ? {...sub, quantity: value} : sub);
        }

        setOptions([...options]);
    }

    const handleOnChangeIngredients = (idIngredient) => {
        setIngredients(ing => ing.map(ing => ing.id === idIngredient ? {...ing, quantity: ing.quantity ? 0 : 1} : ing))
    }


    const handleOnChangeOptionHalf = (parentId, id, half, quantity) => {
        const value = quantity <= 0 ? 0 : quantity;
        const option = optionsHalf.find(op => op.id === parentId && op.half === half);
        option.suboptions = option.suboptions.map(sub => ({
            ...sub, quantity: id === sub.id ? value : sub.quantity,
        }));

        setOptionsHalf([...optionsHalf]);
    }

    const onChangeIsHalf = (isHalf) => {
        setIsHalf(isHalf);
        setOptionsHalf(prepareHalfOptions(optionsHalf, isHalf));
    }

    const onAdd = () => {
        const newQuantity = quantity +1;
        if(product.max !== 0 && newQuantity > product.max) {
            return;
        }

        setQuantity(newQuantity);
    }

    const handleOnChangeQuantity = newQuantity => {
        if(product.max !== 0 && newQuantity > product.max) {
            return;
        }
        setQuantity(newQuantity);
    }

    const onRemove = () => {
        const newQuantity = quantity -1;
        if(0 > newQuantity) {
            return;
        }

        setQuantity(newQuantity);
    }

    const onAddToCart = () => {
        handleOnAddToCart({
            ...productForSave,
            product: {
                ...productForSave.product,
                ingredients: ingredients.filter(el => !el.quantity).map(el => el.id),
                comment: comments,
                name_on_box: nameOnBox,
            }
        });
        setProductForSave(null);
        onClose();
    }


    return (
        <Modal isOpen={isOpen} className={classnames("modalAddDish")}>
            <div className={"modalAddDishContent"}>
                <IconClose onClick={onClose} size={'small'} className={'iconClose'}/>
                <ProductConstructor
                    disableAutoScroll={null}
                    updateOptionOfActualData={(opt) => opt}
                    summaryOpen={!!productEdit}
                    savedProduct={productEdit}
                    media={{
                        src: product.images || '',
                        settings: product.image_settings,
                        alt: product.name,
                        autoPlay: true,
                        controls: true
                    }}
                    price={product.price}
                    oldPrice={product.price_before_discount}
                    totalPrice={totalPrice}
                    isLoading={isLoading}
                    id={product.id}
                    name={product.name}
                    currency={currency}
                    allowComments={product.allow_comments}
                    quantity={quantity}
                    ingredients={ingredients}
                    options={options.filter(opt => checkRespectTo(opt, options.concat(optionsHalf))).filter(opt => opt.suboptions.length)}
                    optionsHalf={optionsHalf.filter(opt => checkRespectTo(opt, options.concat(optionsHalf))).filter(opt => opt.suboptions.length)}
                    isHalf={isHalf}
                    nameOnBox={nameOnBox}
                    comments={comments}
                    onChangeComment={setComments}
                    onChangeNameOnBox={setNameOnBox}
                    onChangeOption={handleOnChangeOption}
                    onChangeIngredients={handleOnChangeIngredients}
                    onChangeOptionHalf={handleOnChangeOptionHalf}
                    onChangeIsHalf={onChangeIsHalf}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onAddToCart={onAddToCart}
                    onChangeQuantity={handleOnChangeQuantity}
                />
            </div>
        </Modal>
    )
}

export default ProductConstructorModal;


const getServerModelOptions = (options) => {
    return options.map(opt => ({
        id: opt.id,
        name: opt.name,
        suboptions: opt.suboptions.filter(sub => !!sub.quantity).map(sub => ({
            id: sub.id,
            name: sub.name,
            price: sub.price,
            quantity: sub.quantity,
            half_option: opt.half
        }))
    })).filter(opt => !!opt.suboptions.length);
}