import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { DIALOG_ACTION_TYPE, WAITER_BUTTON_TYPE_STYLE, WAITER_TYPES } from "../../../utils/enums";

import WaiterButton from "../WaiterButton";
import ConfirmDialog from "../ConfirmDialog";

const DepotIntegrationInfoControls = ({ t, isEdited, onSave, onDelete, onCancel }) => {
    const [open, setOpen] = useState(null);

    const handleOnDelete = () => {
        return new Promise(resolve=> {
            resolve();
            setOpen({ type: DIALOG_ACTION_TYPE.delete });
        });
    }

    const handleOnConfirmDialog = () => {
        return new Promise((resolve, reject)=> {
            switch (open.type) {
                case DIALOG_ACTION_TYPE.delete:
                    onDelete().then(()=> {
                        setOpen(null);
                        resolve();                        
                    }, error=> {
                        setOpen(null);
                        reject(error);
                    });
                    break;    
                default:
                    reject();
                    break; 
            } 
        });

               
    }

    const handleOnCancelDialog = () => {
        return new Promise(resolve=> {
            resolve();
            setOpen(null);
        });        
    }

    return (
        <div className={classNames("depotIntegrationInfoControls")}>                
            <WaiterButton type={WAITER_TYPES.button} 
                typeStyle={WAITER_BUTTON_TYPE_STYLE.confirm}
                title={"save"/*t("basic.SAVE_BUTTON")*/} 
                disabled={!isEdited}
                onClick={onSave} 
            />
            <WaiterButton type={WAITER_TYPES.button} title={"delete"/*t("basic.DELETE_BUTTON")*/} 
                onClick={handleOnDelete} 
            />
            <WaiterButton type={WAITER_TYPES.button} title={"cancel"/*t("basic.CANCEL_BUTTON")*/} 
                onClick={onCancel} 
            />
            {open && (
                <ConfirmDialog onConfirm={handleOnConfirmDialog} onCancel={handleOnCancelDialog} />
            )}
        </div>
    );
}

export default withTranslation()(DepotIntegrationInfoControls);
